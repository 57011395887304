export const CONTEXT = 'louver'

export const EVENTS = {
  FILE_UPLOAD: 'file_uploaded',
  DOCUMENT_VIEW: 'document_viewed',
  DOCUMENT_DOWNLOAD: 'document_downloaded',
  MESSAGE: 'message_sent',
  CUSTOMER_SIGN_IN_PAGE: 'customer_sign_in_page',
  CUSTOMER_SIGN_IN_REQUEST: 'customer_sign_in_request',
  CUSTOMER_SIGN_IN_REQUEST_SUCCESS: 'customer_sign_in_request_success',
  CUSTOMER_SIGN_IN_REQUEST_FAILURE: 'customer_sign_in_request_failure',
  CUSTOMER_SIGN_IN_LINK_REQUEST: 'customer_sign_in_link_request',
  CUSTOMER_SIGN_IN_LINK_REQUEST_SUCCESS:
    'customer_sign_in_link_request_success',
  CUSTOMER_SIGN_IN_LINK_REQUEST_FAILURE:
    'customer_sign_in_link_request_failure',
  TENDER_OFFER_SELECTED: 'tender_offer_selected',

  NAVBAR_CLICKED: `${CONTEXT}:navbar_clicked`,
  DOCUMENTS_CLICKED: `${CONTEXT}:documents_clicked`,
  FILTER_CLICKED: `${CONTEXT}:filter_clicked`,
  DASHBOARD_MSG_CARD_CLICKED: `${CONTEXT}:messageCard_clicked`,
  ORDERS_CARD_CLICKED: `${CONTEXT}:ordersCard_clicked`,
  ORDERS_CLOSE_SERVICE_CLICKED: `${CONTEXT}:closeService_clicked`,
  ORDERS_MESSAGES_CLICKED: `${CONTEXT}:messaging_clicked`,
  ORDERS_COMMENT_CLICKED: `${CONTEXT}:comment_clicked`,
  JOBS_CARD_CLICKED: `${CONTEXT}:jobsCard_clicked`,
  JOBS_FILTER_DELETE: `${CONTEXT}:filter_delete`,
  JOBS_TAB_CLICKED: `${CONTEXT}:jobTab_clicked`,
  JOBS_APPLY_CLICKED: `${CONTEXT}:applyJob_clicked`,
  JOBS_SEND_QUESTION_CLICKED: `${CONTEXT}:sendRequest_clicked`,
  TENDER_APPLICATION_APPLY_CLICKED: `${CONTEXT}:tenderCTA_clicked`,
  TENDER_PROJECT_DETAILS_CTA_CLICKED: `${CONTEXT}:tenderDetailsCTA_clicked`,
  TENDER_CARD_CLICKED: `${CONTEXT}:tenderCard_clicked`,
  TENDER_QUESTION_CTA_CLICKED: `${CONTEXT}:tenderQuestionCTA_clicked`,
  TENDER_REVOKE_CTA_CLICKED: `${CONTEXT}:tenderRevoke_clicked`,
  PARTNER_PORTFOLIO_CREATE_CTA_CLICKED: `${CONTEXT}:profileCreatePortfolio_clicked`,
  PARTNER_PORTFOLIO_CTA_CLICKED: `${CONTEXT}:profilePortfolio_clicked`,
  MULTI_ORDER_CLICKED: `${CONTEXT}:multiOrder_clicked`,
  MULTI_ORDER_TAB_CLICKED: `${CONTEXT}:multiOrderTab_clicked`,
  DASHBOARD_REVIEWS_CLICKED: `${CONTEXT}:dashboardReviews_clicked`,
}

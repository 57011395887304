import React from 'react'
import PropTypes from 'prop-types'

import {EMAIL, PHONE} from 'utils/const'
import TelLink from 'components/TelLink'
import EmailLink from 'components/EmailLink'

const Help = ({phone, email}) => (
  <small className="text-muted">
    Fragen?
    <br />
    <i className="icon-phone align-middle" /> Rufen Sie uns an:{' '}
    <br className="d-sm-none" />
    <TelLink phone={phone} />
    <br />
    <i className="icon-envelope align-middle" /> Schreiben Sie uns an:{' '}
    <br className="d-sm-none" /> <EmailLink email={email} />
  </small>
)

Help.defaultProps = {
  email: EMAIL,
  phone: PHONE,
}

Help.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
}

export default Help

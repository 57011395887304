import {lazy} from 'react'
import {USER_ROLES} from 'modules/auth/utils/const'

export default [
  {
    isProtected: true,
    exact: true,
    path: '/dashboard',
    component: lazy(() => import('./DashboardOverview')),
    roles: [USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/dashboard/fragments',
    component: lazy(() => import('./DashboardOverview')),
    roles: [USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    path: '/',
    component: lazy(() => import('./Home')),
    roles: [USER_ROLES.PARTNER, USER_ROLES.ADMIN],
  },
]

import {utils} from 'modules/common/Form'
import {
  servicesSchema,
  productsSchema,
} from 'modules/partners/components/partner/PartnerForm/schema'

const {schema} = utils

export default schema.object({
  services: servicesSchema.required('Dieses Feld ist erforderlich'),
  products: productsSchema.required('Dieses Feld ist erforderlich'),
})

import {useEffect, useRef} from 'react'
import noop from 'lodash/noop'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'

/**
 * `useBeforeUnload` hook enables the `beforeunload` event handling,
 * showing a message before the user navigates away from the page.
 *
 * @example
 * // display default dialog box
 * useBeforeunload(ev => ev.preventDefault())
 * // display dialog box with custom message
 * useBeforeunload(() => 'Are you sure you want to navigate away!');
 *
 * @param {Function} handler Function that gets invoked with `event` as its argument
 */
export const useBeforeUnload = (handler = noop) => {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const handleBeforeunload = (ev) => {
      let returnValue

      if (isFunction(handlerRef.current)) {
        returnValue = handlerRef.current(ev)
      }

      if (ev.defaultPrevented) {
        ev.returnValue = ''
      }

      if (isString(returnValue)) {
        ev.returnValue = returnValue
        return returnValue
      }
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [])
}

export default useBeforeUnload

import React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'

import ENV from 'configs/env'
import {getAuthorizationHeaderObject} from 'modules/auth/utils/userUtils'

const {API_V2_URL} = ENV

const httpLink = createHttpLink({
  uri: `${API_V2_URL}/graphql`,
})

const authLink = setContext(async (_, {headers}) => {
  const authHeaders = await getAuthorizationHeaderObject()

  return {headers: {...headers, ...authHeaders}}
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

// eslint-disable-next-line react/prop-types
export const GraphQLProvider = ({children}) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

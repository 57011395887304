import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LogoSrc from 'assets/images/logo.png'
import FullLogoSrc from 'assets/images/logo-full.png'

const LOGO_TYPES = {
  icon: LogoSrc,
  full: FullLogoSrc,
}

const Logo = ({type, className, ...props}) => (
  <img
    className={classNames('img-fluid', className)}
    src={LOGO_TYPES[type] || LOGO_TYPES.icon}
    alt="Construyo Logo"
    {...props}
  />
)

Logo.defaultProps = {
  className: '',
  type: 'full',
}

Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(LOGO_TYPES)),
}

export default Logo

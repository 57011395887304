/* eslint-disable react/prop-types */
import React, {useCallback, useMemo} from 'react'
import {Route} from 'react-router-dom'
import intersection from 'lodash/intersection'
import size from 'lodash/size'
import {useUser} from './UserContext'
import {getUserRoles} from './userUtils'

import Loader from 'components/Loader'
import RedirectToLoginPage from 'modules/common/RedirectToLoginPage'
import NotFound from 'modules/common/Errors/NotFound'
import NewVersion from 'modules/common/NewVersion'

const ProtectedRoute = ({
  component: Component,
  roles,
  hasCustomLayout,
  ...rest
}) => {
  const {loading, value: user} = useUser()

  const hasRolesConfig = !!size(roles)
  const userRoles = useMemo(() => getUserRoles(user), [user])
  const hasAllowedRole = useMemo(
    () => (hasRolesConfig ? !!size(intersection(roles, userRoles)) : true),
    [hasRolesConfig, roles, userRoles]
  )
  const renderer = useCallback(
    (props) => {
      if (loading) return <Loader />

      if (!user) return <RedirectToLoginPage fromLocation={props.location} />

      if (hasRolesConfig && !hasAllowedRole) {
        const notFoundPageProps = hasCustomLayout
          ? {}
          : {
              containerClassName: null,
              hideLogo: true,
            }
        return <NotFound {...notFoundPageProps} />
      }

      return (
        <NewVersion>
          <Component {...props} />
        </NewVersion>
      )
    },
    [loading, user, hasCustomLayout, hasRolesConfig, hasAllowedRole]
  )

  return <Route {...rest} render={renderer} />
}

export default ProtectedRoute

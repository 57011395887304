import {useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import qs from 'qs'
import debounce from 'lodash/debounce'
import defaults from 'lodash/defaults'

import {useUserPermissions} from 'modules/auth/utils/UserContext'

const DEBOUNCE_TIME = 400

export const createURL = (state) => `?${qs.stringify(state)}`

export const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : ''

export const locationSearchParamToSearchState = (search) =>
  search ? qs.parse(search.slice(1)) : {}

export default function useUrl() {
  const history = useHistory()
  const location = useLocation()
  const {isAdmin} = useUserPermissions()

  const {state, search: locationSearchParam} = location
  const searchState = useMemo(
    () =>
      defaults(
        isAdmin ? {} : {is_filter: 'true'},
        state || locationSearchParamToSearchState(locationSearchParam)
      ),
    [state, locationSearchParam, isAdmin]
  )

  const updateUrl = useMemo(
    () =>
      debounce(
        (updatedSearchState) =>
          history.push(
            searchStateToUrl(location, updatedSearchState),
            updatedSearchState
          ),
        DEBOUNCE_TIME
      ),
    [history, location]
  )

  return useMemo(
    () => ({updateUrl, searchState, locationSearchParam}),
    [updateUrl, searchState, locationSearchParam]
  )
}

import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEqual from 'lodash/isEqual'
import flatten from 'flat'

export default function getUpdates(initialValues, updatedValues) {
  const flattenedInitialValues = flatten(initialValues, {safe: true})
  const flattenedUpdatedValues = flatten(updatedValues, {safe: true})

  const updates = reduce(
    flattenedUpdatedValues,
    (updates, val, key) => {
      if (!isEqual(get(flattenedInitialValues, key), val)) {
        updates[key] = val
      }
      return updates
    },
    {}
  )
  return flatten.unflatten(updates)
}

export const DEFAULT_LOCALE = {
  back: 'Zurück',
  close: 'Schließen',
  last: 'Ende',
  next: 'Nächster',
  skip: 'Auslassen',
  open: 'Informationen anzeigen',
}

export const DEFAULT_STYLES = {
  arrowColor: 'var(--white)',
  backgroundColor: 'var(--white)',
  beaconSize: 36,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: 'var(--danger)',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: 'var(--dark)',
}

export const DEFAULT_FLOATER_STYLES = {
  wrapper: {zIndex: 9999},
  floater: {zIndex: 9999},
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Row, Col, Spinner} from 'reactstrap'

import Logo from 'components/Logo'
import style from './index.module.scss'

const Loader = ({text, type, spinnerProps, isFullPage}) => {
  const LOADER_TYPES = {
    logo: <Logo />,
    spinner: <Spinner {...spinnerProps} />,
  }
  return (
    <div
      className={classNames(style.wrapper, {
        [style.wrapper_fullPage]: isFullPage,
      })}>
      <div
        className={classNames(style.container, {
          [style.container_fullPage]: isFullPage,
        })}>
        <Row>
          <Col>{LOADER_TYPES[type] || null}</Col>
        </Row>
        <Row>
          <Col>{text}</Col>
        </Row>
      </div>
    </div>
  )
}

Loader.defaultProps = {
  type: 'logo',
  isFullPage: true,
  spinnerProps: {
    type: 'border',
    color: 'success',
    size: 'md',
  },
}

Loader.propTypes = {
  isFullPage: PropTypes.bool,
  spinnerProps: PropTypes.object,
  text: PropTypes.node,
  type: PropTypes.oneOf(['logo', 'spinner']),
}

export default Loader

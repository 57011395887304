import {useMemo} from 'react'
import {useField, useFormikContext} from 'formik'

import useId from 'hooks/useId'

export default function useFormField({disabled, id: fieldId, ...props}) {
  const [field, meta, helpers] = useField(props)
  const formContext = useFormikContext()
  const domId = useId()

  const id = useMemo(() => fieldId || domId, [fieldId, domId])
  const {touched, error} = meta
  const {isSubmitting} = formContext
  const hasError = !!(touched && error)
  const isDisabled = isSubmitting || disabled

  return {field, meta, helpers, formContext, hasError, isDisabled, id}
}

import {useDocumentDataOnce} from 'react-firebase-hooks/firestore'
import {db} from 'configs/firebase'
import get from 'lodash/get'

const useConfigOnce = (name, path) => {
  const {error, loading, value} = useDocumentDataOnce(db.doc(`configs/${name}`))

  if (error || loading || !value) return null

  return path ? get(value, path) : value
}

export default useConfigOnce

import transform from 'lodash/transform'
import each from 'lodash/each'
import toInteger from 'lodash/toInteger'
import invert from 'lodash/invert'
import mapValues from 'lodash/mapValues'

export const CONSTRUYO_NAME = 'Construyo'
export const EMAIL = 'info@construyo.de'
export const FINANCE_EMAIL = 'buchhaltung@construyo.de'
export const PHONE = '030896779777'
export const PARTUM_PARTNER_ID = 'E6UhhsnD0fZcHjZ1y1Nr'
export const CONSTRUYO_OPS_MESSAGE_USER_ID = 'CONSTRUYO-OPS'
export const CONSTRUYO_OPS_MESSAGE_USER_NAME = 'Construyo Kundendienst'
export const CONSTRUYO_LOCATION = {lat: 52.5293481, lng: 13.3899912}
export const CONSTRUYO_AGB_URL = 'https://www.construyo.de/agb/'
export const CONSTRUYO_URL = 'https://www.construyo.de/'
export const CONSTRUYO_IMPRESSUM_URL = 'https://www.construyo.de/impressum/'
export const DEFAULT_VAT = 0.19

export const CRUD = {
  read: 'get',
  create: 'post',
  update: 'put',
  delete: 'delete',
}

export const PAYMENT_TYPE = {
  pre: 0,
  post: 1,
  mixed: 2,
}

export const PRODUCTS = {
  STATICS: 'STATICS',
  REINFORCEMENTS: 'REINFORCEMENTS',
  SOUND_PROOFING: 'SOUND_PROOFING',
  FIRE_PROTECTION: 'FIRE_PROTECTION',
  HEAT_PROTECTION: 'HEAT_PROTECTION',
}

export const PRODUCT_IDS = {
  [PRODUCTS.STATICS]: [
    '01t1t000001ifixAAA',
    '01t1t000001ii3uAAA',
    '01t1t000001ii3pAAA',
    '01t1t0000025oP2AAI',
    '01t1t000001ii4KAAQ',
    '01t1t000001ifisAAA',
  ],
  [PRODUCTS.REINFORCEMENTS]: ['01t1t000001ii4JAAQ'],
  [PRODUCTS.SOUND_PROOFING]: ['01t1t000001ii4EAAQ'],
  [PRODUCTS.FIRE_PROTECTION]: ['01t1t000001ii45AAA'],
  [PRODUCTS.HEAT_PROTECTION]: ['01t1t000001ii49AAA'],
}

export const TASK_STATE = {
  scheduled: 'scheduled',
  completed: 'completed',
  failed: 'failed',
}

export const PROGRESS_STATUS = {
  cancelled: 0,
  open: 1,
  inProgress: 2,
  done: 3,
}

export const ORDER_LEGACY_STATUS = {
  cancelled: 0,
  sold: 1,
  scheduled: 2,
  conducted: 3,
  billed: 4,
  paid: 5,
  feedback: 6,
  closed: 7,
}

export const JOB_APPLICATION_STATUS = {
  cancelled: 0,
  open: 1,
  approved: 2,
  declined: 3,
}

export const PARTNER_SERVICES = {
  structuralEngineer: 'structural_engineer',
  architect: 'architect',
  builder: 'builder',
  energyConsultant: 'energy_consultant',
  surveyor: 'surveyor',
  fireProtectionEngineer: 'fire_protection_engineer',
  buildingExpert: 'building_expert',
  civilEngineer: 'civil_engineer',
  soundproofingEngineer: 'soundproofing_engineer',
  electrician: 'electrician',
  plantMechanic: 'plant_mechanic',
  roofer: 'roofer',
  drywallBuilder: 'drywall_builder',
  painter: 'painter',
  windowFitter: 'window_fitter',
  carpenter: 'carpenter',
  gardener: 'gardener',
  buildingServicesPlanner: 'building_services_planner',
  floorLayer: 'floor_layer',
  shellBuilder: 'shell_builder',
}

export const PARTNER_SERVICES_NAMES = {
  [PARTNER_SERVICES.architect]: 'Architekt/in',
  [PARTNER_SERVICES.structuralEngineer]: 'Tragwerksplaner/in',
  [PARTNER_SERVICES.energyConsultant]: 'Energieberater/in',
  [PARTNER_SERVICES.builder]: 'Generalunternehmer/in',
  [PARTNER_SERVICES.surveyor]: 'Vermesser/in',
  [PARTNER_SERVICES.fireProtectionEngineer]: 'Brandschutzingenieur/in',
  [PARTNER_SERVICES.buildingExpert]: 'Baugrundgutacher/in',
  [PARTNER_SERVICES.civilEngineer]: 'Bauingenieur/in',
  [PARTNER_SERVICES.electrician]: 'Elektriker',
  [PARTNER_SERVICES.plantMechanic]: 'Sanitär/Heizung/Klima Anlagenmechanik',
  [PARTNER_SERVICES.roofer]: 'Dachdecker/in',
  [PARTNER_SERVICES.drywallBuilder]: 'Trockenbauer/in',
  [PARTNER_SERVICES.painter]: 'Maler/in',
  [PARTNER_SERVICES.windowFitter]: 'Fensterbauer/in',
  [PARTNER_SERVICES.carpenter]: 'Zimmerer/in',
  [PARTNER_SERVICES.gardener]: 'Garten-/Landschaftsbauer/in',
  [PARTNER_SERVICES.buildingServicesPlanner]: 'TGA Planer/in',
  [PARTNER_SERVICES.floorLayer]: 'Bodenleger/in',
  [PARTNER_SERVICES.shellBuilder]: 'Rohbauer/in',
}

export const PARTNER_WEAKNESSES = {
  none: 'none',
  slowSpeed: 'slow_speed',
  highPrice: 'high_price',
  feeRigid: 'fee_rigid',
  poorAccessibility: 'poor_accessibility',
  lowReactivity: 'low_reactivity',
  lowFlexibility: 'low_flexibility',
  lowWillingnessToTravel: 'low_willingness_to_travel',
  littleWillingnessForSiteVisits: 'little_willingness_for_site_visits',
  notReadyForSiteVisits: 'not_ready_for_site_visits',
  limitedRangeOfServices: 'limited_range_of_services',
  difficultCommunication: 'difficult_communication',
  lowCapacities: 'low_capacities',
  smallNetwork: 'small_network',
  selfishBehavior: 'selfish_behavior',
  negativeExperiencesWithConstruyo: 'negative_experiences_with_construyo',
  prefersCertainOrderSizes: 'prefers_certain_order_sizes',
  monolingual: 'monolingual',
  badCustomerDealings: 'bad_customer_dealings',
  lowAffinityForTechnology: 'low_affinity_for_technology',
  regional: 'regional',
}

export const PARTNER_STRENGTHS = {
  highSpeed: 'high_speed',
  lowPrice: 'low_price',
  negotiableFee: 'negotiable_fee',
  goodAccessibility: 'good_accessibility',
  highReactivity: 'high_reactivity',
  flexibility: 'flexibility',
  highWillingnessToTravel: 'high_willingness_to_travel',
  readinessForSiteVisits: 'readiness_for_site_visits',
  wideRangeOfServices: 'wide_range_of_services',
  openCommunication: 'open_communication',
  manyCapacities: 'many_capacities',
  largeNetwork: 'large_network',
  fairness: 'fairness',
  positiveAttitudeTowardsConstruyo: 'positive_attitude_towards_construyo',
  flexibleWithOrderSize: 'flexible_with_order_size',
  preferLargeProjects: 'prefer_large_projects',
  preferSmallProjects: 'prefer_small_projects',
  multilingual: 'multilingual',
  goodCustomerRelations: 'good_customer_relations',
  highAffinityForTechnology: 'high_affinity_for_technology',
  longTermPartner: 'long_term_partner',
  b2bPotential: 'b2b_potential',
  eligibleToSubmit: 'eligible_to_submit',
  nationwide: 'nationwide',
}

export const PARTNER_BUDGETS = {
  indigo: 'indigo', // low order value
  green: 'green', // medium order value
  red: 'red', // high order value
}

export const PRODUCTS_CATEGORIES = {
  general: 0,
  architecture: 1,
  construction: 2,
  soil: 3,
  fireProtection: 4,
  energyAndThermalProtection: 5,
  soundproofing: 6,
  structuralEngineer: 7,
  shell: 8,
  finishingTrades: 9,
  houseTechnic: 10,
  buildingServicesPlanning: 11,
  roof: 12,
}

export const PRODUCTS_CATEGORIES_NAMES = {
  [PRODUCTS_CATEGORIES.general]: 'Allgemein',
  [PRODUCTS_CATEGORIES.architecture]: 'Architektur',
  [PRODUCTS_CATEGORIES.construction]: 'Generalunternehmer',
  [PRODUCTS_CATEGORIES.soil]: 'Baugrund',
  [PRODUCTS_CATEGORIES.fireProtection]: 'Brandschutz',
  [PRODUCTS_CATEGORIES.energyAndThermalProtection]: 'Energie & Wärmeschutz',
  [PRODUCTS_CATEGORIES.soundproofing]: 'Schallschutz',
  [PRODUCTS_CATEGORIES.structuralEngineer]: 'Statiker / Tragwerksplaner',
  [PRODUCTS_CATEGORIES.shell]: 'Rohbau',
  [PRODUCTS_CATEGORIES.finishingTrades]: 'Ausbaugewerbe',
  [PRODUCTS_CATEGORIES.houseTechnic]: 'Haustechnik',
  [PRODUCTS_CATEGORIES.buildingServicesPlanning]: 'TGA Planung',
  [PRODUCTS_CATEGORIES.roof]: 'Dach',
}

export const PRODUCT_CATEGORIES_TO_PARTNER_SERVICES_MAP = {
  [PRODUCTS_CATEGORIES.general]: [
    PARTNER_SERVICES.civilEngineer,
    PARTNER_SERVICES.surveyor,
    PARTNER_SERVICES.architect,
    PARTNER_SERVICES.structuralEngineer,
  ],
  [PRODUCTS_CATEGORIES.architecture]: [PARTNER_SERVICES.architect],
  [PRODUCTS_CATEGORIES.construction]: [
    PARTNER_SERVICES.builder,
    PARTNER_SERVICES.electrician,
    PARTNER_SERVICES.buildingServicesPlanner,
    PARTNER_SERVICES.gardener,
    PARTNER_SERVICES.carpenter,
    PARTNER_SERVICES.windowFitter,
    PARTNER_SERVICES.painter,
    PARTNER_SERVICES.drywallBuilder,
    PARTNER_SERVICES.roofer,
    PARTNER_SERVICES.plantMechanic,
  ],
  [PRODUCTS_CATEGORIES.soil]: [PARTNER_SERVICES.buildingExpert],
  [PRODUCTS_CATEGORIES.fireProtection]: [
    PARTNER_SERVICES.fireProtectionEngineer,
  ],
  [PRODUCTS_CATEGORIES.energyAndThermalProtection]: [
    PARTNER_SERVICES.energyConsultant,
    PARTNER_SERVICES.structuralEngineer,
  ],
  [PRODUCTS_CATEGORIES.soundproofing]: [PARTNER_SERVICES.soundproofingEngineer],
  [PRODUCTS_CATEGORIES.structuralEngineer]: [
    PARTNER_SERVICES.structuralEngineer,
  ],
  [PRODUCTS_CATEGORIES.shell]: [
    PARTNER_SERVICES.roofer,
    PARTNER_SERVICES.builder,
    PARTNER_SERVICES.shellBuilder,
  ],
  [PRODUCTS_CATEGORIES.finishingTrades]: [
    PARTNER_SERVICES.drywallBuilder,
    PARTNER_SERVICES.painter,
    PARTNER_SERVICES.windowFitter,
    PARTNER_SERVICES.carpenter,
  ],
  [PRODUCTS_CATEGORIES.houseTechnic]: [
    PARTNER_SERVICES.plantMechanic,
    PARTNER_SERVICES.electrician,
  ],
  [PRODUCTS_CATEGORIES.buildingServicesPlanning]: [
    PARTNER_SERVICES.buildingServicesPlanner,
  ],
  [PRODUCTS_CATEGORIES.roof]: [PARTNER_SERVICES.roofer],
}

const partnerServicesToCategories = transform(
  PRODUCT_CATEGORIES_TO_PARTNER_SERVICES_MAP,
  function (result, partnerServices, category) {
    each(partnerServices, (partnerService) => {
      ;(result[partnerService] || (result[partnerService] = [])).push(
        toInteger(category)
      )
    })
  },
  {}
)
export const PARTNER_SERVICES_TO_PRODUCT_CATEGORIES_MAP = mapValues(
  invert(PARTNER_SERVICES),
  (val, key) =>
    partnerServicesToCategories[key] || [PRODUCTS_CATEGORIES.general]
)

export const PROJECT_TYPE_GROUPS = {
  new_building: 0,
  renovation: 1,
  extension: 2,
  breach: 3,
  garden: 4,
  other: 5,
}

export const NOTIFICATION = {
  ENTITY_TYPE: {
    message: 'message',
    comment: 'comment',
  },
  REFERENCE_TYPE: {
    order: 'order',
  },
}

export const COLLECTIONS = {
  orders: 'orders',
  invoices: 'invoices',
  tenders: 'tenders',
  applications: 'tenderApplications',
  users: 'users',
  acceptanceProtocols: (orderId) =>
    `${COLLECTIONS.orders}/${orderId}/acceptanceProtocols`,
}

import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'

export default [
  {
    exact: true,
    path: '/customers/orders/:orderId',
    component: lazy(() => import('./OrderDetails')),
    customLayout: false,
  },
  {
    exact: true,
    path: '/booking/:offerId/view',
    component: (props) => (
      // eslint-disable-next-line react/prop-types
      <Redirect to={`/customers/offers/${props.match.params.offerId}`} />
    ),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/offers/:offerId',
    component: lazy(() => import('./Offer')),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/:opportunityId/documents',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/customers/${props.match.params.opportunityId}/documents/upload`}
      />
    ),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/:opportunityId/documents/:action?',
    component: lazy(() => import('./Documents/OpportunityDocuments')),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/lead/:leadId/documents/upload',
    component: lazy(() => import('./Documents/LeadDocuments/DocumentsUpload')),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/lead/:leadId/documents',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/customers/lead/${props.match.params.leadId}/documents/upload`}
      />
    ),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/orders/:orderId/revisions/:revisionId',
    component: lazy(() => import('./OrderRevision')),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/orders/:orderId/protocols/:acceptanceProtocolId/:action',
    component: lazy(() => import('./AcceptanceProtocol')),
    customLayout: true,
  },
  {
    exact: true,
    path: '/customers/orders/:orderId/protocols/:acceptanceProtocolId',
    component: lazy(() => import('./AcceptanceProtocol')),
    customLayout: true,
  },
]

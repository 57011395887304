import React, {useContext} from 'react'
import {useCollectionDataOnce} from 'react-firebase-hooks/firestore'
import {db} from 'configs/firebase'
import {useUser} from 'modules/auth/utils/UserContext'
import keyBy from 'lodash/keyBy'

const selectEnabled = (d) => d.isEnabled

const PartnersContext = React.createContext({
  error: null,
  loading: true,
  value: null,
})

export const dropdownFormatter = ({
  uid,
  isEnabled,
  name,
  address,
  contact,
  internalComment,
  emails,
  phones,
  services,
  products,
  strengths,
  weaknesses,
  score,
  metrics,
}) => ({
  label: name,
  value: uid,
  uid,
  isEnabled,
  name,
  address,
  contact,
  internalComment,
  emails,
  phones,
  services,
  products,
  strengths,
  weaknesses,
  score,
  metrics,
})

export const FORMATS = {
  default: 'DEFAULT',
  dropdown: 'DROPDOWN',
  flat: 'FLAT',
}

export const usePartners = ({
  showDisabled = false,
  format = FORMATS.default,
} = {}) => {
  const formattedPartnersMap = useContext(PartnersContext)

  const {
    all = [],
    enabled = [],
    flattened = {},
    flattenedEnabled = {},
  } = formattedPartnersMap || {}

  if (showDisabled) {
    return format === FORMATS.default
      ? all
      : format === FORMATS.flat
      ? flattened
      : format === FORMATS.dropdown
      ? all.map(dropdownFormatter)
      : all
  }

  return format === FORMATS.default
    ? enabled
    : format === FORMATS.flat
    ? flattenedEnabled
    : format === FORMATS.dropdown
    ? enabled.map(dropdownFormatter)
    : enabled
}

export const usePartnersQuery = (user) => {
  const query = db.collection('partners')

  // @FIXME:
  // for implementing the customer area, we need partner name
  // but the customer user may or may not be the app user
  // as such, we cannot run any checks to filter partners from the list
  // if (user) {
  //   const isAdmin = get(user, 'permissions.admin', false)
  //   const partnerId = get(user, 'partnerId', null)

  //   if (!isAdmin) query = query.where('uid', '==', partnerId)
  // }

  const {error, loading, value} = useCollectionDataOnce(query)
  let formattedPartnersMap = null

  if (value) {
    const enabled = value.filter(selectEnabled)
    const flattened = keyBy(value, 'uid')
    const flattenedEnabled = keyBy(enabled, 'uid')

    formattedPartnersMap = {
      all: value,
      enabled,
      flattened,
      flattenedEnabled,
    }
  }

  return {error, loading, value: formattedPartnersMap}
}

// eslint-disable-next-line react/prop-types
export const PartnersProvider = ({children}) => {
  const {value: user} = useUser()
  const {error, loading, value} = usePartnersQuery(user)

  return (
    <PartnersContext.Provider value={loading || error ? null : value}>
      {children}
    </PartnersContext.Provider>
  )
}

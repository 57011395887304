import {useState, useCallback} from 'react'
import * as Cookies from 'js-cookie'

export default function useCookie(key, initialValue) {
  const [item, setInnerValue] = useState(() => {
    return Cookies.get(key) || initialValue
  })

  const setValue = useCallback(
    (value, options) => {
      setInnerValue(value)
      Cookies.set(key, value, options)
    },
    [key]
  )

  return [item, setValue]
}

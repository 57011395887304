import React from 'react'
import PropTypes from 'prop-types'
import size from 'lodash/size'

import {Button} from 'reactstrap'

import Stepper from 'components/Stepper'
import {useFormContext} from 'modules/common/Form'
import useFormField from 'modules/common/Form/hooks/useFormField'

import ProfessionsStep from './Professions'
import ProductsStep from './Products'

const Steps = ({partner, onClickSkip}) => {
  const {submitForm, isValid, isSubmitting} = useFormContext()
  const {
    field: {value},
  } = useFormField({name: 'services'})

  const hasProfessions = size(value)

  return (
    <Stepper
      steps={[
        {
          progressLabel: 'Berufe',
          children: <ProfessionsStep partner={partner} />,
        },
        {
          progressLabel: 'Dienstleistungen',
          children: <ProductsStep partner={partner} />,
        },
      ]}
      additionalBtns={
        <Button color="link" onClick={onClickSkip} disabled={isSubmitting}>
          Später
        </Button>
      }
      onClickFinish={submitForm}
      isFinishDisabled={!isValid || isSubmitting}
      isNextDisabled={!hasProfessions || isSubmitting}
      isPreviousDisabled={isSubmitting}
    />
  )
}

Steps.propTypes = {
  onClickSkip: PropTypes.func,
  partner: PropTypes.object,
}

export default Steps

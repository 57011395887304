const CONFIGS = {
  production: {
    API_URL: 'https://api.construyo.de/v1',
    API_V2_URL: 'https://apiv2.construyo.de',
    WEBAPP_URL: 'https://app.construyo.de',
    BOOKING_URL: 'https://buchung.construyo.de/booking',
    CUSTOMER_URL: 'https://buchung.construyo.de/customers',
    SALESFORCE_URL: 'https://construyo.lightning.force.com/lightning',
    PIPEDRIVE_URL: 'https://construyo.pipedrive.com',
    FIREBASE: {
      apiKey: 'AIzaSyBjesQIHckiOme5BaXpoc6DC99I5SsCEZ4',
      authDomain: 'partumgmbh.firebaseapp.com',
      databaseURL: 'https://partumgmbh.firebaseio.com',
      projectId: 'partumgmbh',
      storageBucket: 'partumgmbh.appspot.com',
      messagingSenderId: '558650533237',
      appId: '1:558650533237:web:9ded4a22214e11581e1f3c',
      measurementId: 'G-XBT9HFP7XS',
    },
    ALGOLIA: {
      appId: 'OYOU7OIIV5',
      apiKey: '4759c66553acf4bb2a34fee107b3eefc',
    },
    GOOGLE: {
      API_KEY: 'AIzaSyBjesQIHckiOme5BaXpoc6DC99I5SsCEZ4',
    },
  },
  staging: {
    API_URL: 'https://construyo-api-staging.herokuapp.com/v1',
    API_V2_URL: 'https://apiv2-staging.construyo.de',
    WEBAPP_URL: 'https://app-staging.construyo.de',
    BOOKING_URL: 'https://buchung-staging.construyo.de/booking',
    CUSTOMER_URL: 'https://buchung-staging.construyo.de/customers',
    SALESFORCE_URL:
      'https://construyo--setup.sandbox.lightning.force.com/lightning',
    PIPEDRIVE_URL: 'https://construyo.pipedrive.com',
    FIREBASE: {
      apiKey: 'AIzaSyAzb-Dfd8zHgRoUwFRRqr3BGjO0wEGd4pA',
      authDomain: 'partum-stage.firebaseapp.com',
      databaseURL: 'https://partum-stage.firebaseio.com',
      projectId: 'partum-stage',
      storageBucket: 'partum-stage.appspot.com',
      messagingSenderId: '1076030310527',
      appId: '1:1076030310527:web:481db48907d176df3d974a',
      measurementId: 'G-RYFT3RTNWC',
    },
    ALGOLIA: {
      appId: process.env.REACT_APP_ALGOLIA_APP_ID || 'FF84XXNWRK',
      apiKey:
        process.env.REACT_APP_ALGOLIA_API_KEY ||
        'bbcf65bea6fad9a0b818b01332db3568',
    },
    GOOGLE: {
      API_KEY: 'AIzaSyBjesQIHckiOme5BaXpoc6DC99I5SsCEZ4',
    },
  },
}

const DEV_CONFIGS = {
  ...CONFIGS.staging,
  API_URL: process.env.REACT_APP_API_URL,
  API_V2_URL: process.env.REACT_APP_API_V2_URL,
}
const env = CONFIGS[process.env.REACT_APP_DEPLOYMENT_ENV] || DEV_CONFIGS

export default env

import React from 'react'
import map from 'lodash/map'

import Text from 'components/Text'
import {
  PARTNER_SERVICES_NAMES,
  PARTNER_STRENGTHS,
  PARTNER_WEAKNESSES,
  PARTNER_BUDGETS,
} from 'utils/const'

export const ONBOARDING_COOKIE_NAME = '__partner__onboarding__'

export const COMPLIANCE_DOCUMENT_TYPES = {
  CHAMBER_LISTING: 'CHAMBER_LISTING',
  INSURANCE: 'INSURANCE',
}

export const DOCUMENT_TYPE_NAMES = {
  [COMPLIANCE_DOCUMENT_TYPES.CHAMBER_LISTING]: 'Kammermitgliedschaft',
  [COMPLIANCE_DOCUMENT_TYPES.INSURANCE]: 'Versicherung',
}

export const DocumentTypeDropdownValues = [
  {
    value: COMPLIANCE_DOCUMENT_TYPES.CHAMBER_LISTING,
    label: (
      <>
        <i className="fa fa-institution" />{' '}
        <Text>
          {DOCUMENT_TYPE_NAMES[COMPLIANCE_DOCUMENT_TYPES.CHAMBER_LISTING]}
        </Text>
      </>
    ),
  },
  {
    value: COMPLIANCE_DOCUMENT_TYPES.INSURANCE,
    label: (
      <>
        <i className="fa fa-money" />{' '}
        <Text>{DOCUMENT_TYPE_NAMES[COMPLIANCE_DOCUMENT_TYPES.INSURANCE]}</Text>
      </>
    ),
  },
]

export const PROFESSIONS = map(PARTNER_SERVICES_NAMES, (val, key) => ({
  label: val,
  value: key,
}))

export const STRENGTHS_OPTIONS = [
  {value: PARTNER_STRENGTHS.highSpeed, label: 'hohe Geschwindigkeit'},
  {value: PARTNER_STRENGTHS.lowPrice, label: 'niedriger Preis'},
  {value: PARTNER_STRENGTHS.negotiableFee, label: 'Honorar verhandelbar'},
  {value: PARTNER_STRENGTHS.goodAccessibility, label: 'gute Erreichbarkeit'},
  {value: PARTNER_STRENGTHS.highReactivity, label: 'hohe Reaktivität'},
  {value: PARTNER_STRENGTHS.flexibility, label: 'Flexibilität'},
  {
    value: PARTNER_STRENGTHS.highWillingnessToTravel,
    label: 'hohe Reisebereitschaft',
  },
  {
    value: PARTNER_STRENGTHS.readinessForSiteVisits,
    label: 'Bereitschaft für Ortstermine',
  },
  {
    value: PARTNER_STRENGTHS.wideRangeOfServices,
    label: 'breites Leistungsspektrum',
  },
  {value: PARTNER_STRENGTHS.openCommunication, label: 'offene Kommunikation'},
  {value: PARTNER_STRENGTHS.manyCapacities, label: 'viele Kapazitäten'},
  {value: PARTNER_STRENGTHS.largeNetwork, label: 'großes Netzwerk'},
  {value: PARTNER_STRENGTHS.fairness, label: 'Fairness'},
  {
    value: PARTNER_STRENGTHS.positiveAttitudeTowardsConstruyo,
    label: 'Positive Einstellung gegenüber Construyo',
  },
  {
    value: PARTNER_STRENGTHS.flexibleWithOrderSize,
    label: 'Flexibel bei Auftragsgröße',
  },
  {
    value: PARTNER_STRENGTHS.preferLargeProjects,
    label: 'Bevorzugt große Projekte',
  },
  {
    value: PARTNER_STRENGTHS.preferSmallProjects,
    label: 'Bevorzugt kleine Projekte',
  },
  {value: PARTNER_STRENGTHS.multilingual, label: 'Mehrsprachig'},
  {value: PARTNER_STRENGTHS.goodCustomerRelations, label: 'guter Kundenumgang'},
  {
    value: PARTNER_STRENGTHS.highAffinityForTechnology,
    label: 'hohe Technologieaffinität',
  },
  {value: PARTNER_STRENGTHS.longTermPartner, label: 'Langzeit Partner'},
  {value: PARTNER_STRENGTHS.b2bPotential, label: 'B2B Potential'},
  {value: PARTNER_STRENGTHS.eligibleToSubmit, label: 'Vorlageberechtigt'},
  {value: PARTNER_STRENGTHS.nationwide, label: 'Bundesweit'},
]

export const WEAKNESSES_OPTIONS = [
  {value: PARTNER_WEAKNESSES.none, label: 'keine Schwächen'},
  {value: PARTNER_WEAKNESSES.slowSpeed, label: 'langsame Geschwindigkeit'},
  {value: PARTNER_WEAKNESSES.highPrice, label: 'hoher Preis'},
  {value: PARTNER_WEAKNESSES.feeRigid, label: 'Honorar starr'},
  {
    value: PARTNER_WEAKNESSES.poorAccessibility,
    label: 'schlechte Erreichbarkeit',
  },
  {value: PARTNER_WEAKNESSES.lowReactivity, label: 'geringe Reaktivität'},
  {value: PARTNER_WEAKNESSES.lowFlexibility, label: 'niedrige Flexibilität'},
  {
    value: PARTNER_WEAKNESSES.lowWillingnessToTravel,
    label: 'geringe Reisebereitschaft',
  },
  {
    value: PARTNER_WEAKNESSES.littleWillingnessForSiteVisits,
    label: 'wenig Bereitschaft für Ortstermine',
  },
  {
    value: PARTNER_WEAKNESSES.notReadyForSiteVisits,
    label: 'keine Bereitschaft für Ortstermine',
  },
  {
    value: PARTNER_WEAKNESSES.limitedRangeOfServices,
    label: 'begrenztes Leistungsspektrum',
  },
  {
    value: PARTNER_WEAKNESSES.difficultCommunication,
    label: 'schwierige Kommunikation',
  },
  {value: PARTNER_WEAKNESSES.lowCapacities, label: 'geringe Kapazitäten'},
  {value: PARTNER_WEAKNESSES.smallNetwork, label: 'kleines Netzwerk'},
  {value: PARTNER_WEAKNESSES.selfishBehavior, label: 'eigennütziges Verhalten'},
  {
    value: PARTNER_WEAKNESSES.negativeExperiencesWithConstruyo,
    label: 'negative Erfahrungen mit Construyo',
  },
  {
    value: PARTNER_WEAKNESSES.prefersCertainOrderSizes,
    label: 'präferiert bestimmte Auftragsgrößen',
  },
  {value: PARTNER_WEAKNESSES.monolingual, label: 'Einsprachig'},
  {
    value: PARTNER_WEAKNESSES.badCustomerDealings,
    label: 'schlechter Kundenumgang',
  },
  {
    value: PARTNER_WEAKNESSES.lowAffinityForTechnology,
    label: 'geringe Technologieaffinität',
  },
  {value: PARTNER_WEAKNESSES.regional, label: 'Regional'},
]

export const CATEGORIES_OPTIONS = [
  {
    label: 'Budget',
    options: [
      {value: PARTNER_BUDGETS.indigo, label: 'Honorar Niedrig'},
      {value: PARTNER_BUDGETS.green, label: 'Honorar Mittel'},
      {value: PARTNER_BUDGETS.red, label: 'Honorar Hoch'},
    ],
  },
]

export const SETTINGS_TABS = {
  INFORMATION: {
    index: 0,
    tabName: 'information',
    label: 'Information',
  },
  DOCUMENTS: {
    index: 1,
    tabName: 'documents',
    label: 'Dokumente',
  },
  INVOICE_DETAILS: {
    index: 2,
    tabName: 'invoice_details',
    label: 'Rechnungsdetails',
  },
  PROFESSION: {
    index: 3,
    tabName: 'profession',
    label: 'Professionen & Dienstleistungen',
  },
}

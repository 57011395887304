import React, {useMemo} from 'react'
import useConfigOnce from './useConfigOnce'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'

const dropdownFormatter = ({uid, name: label, ...fields}, key) => ({
  ...fields,
  label: fields.deleted ? <s>{label}</s> : label,
  value: uid || key,
})

export default function useProductsConfig({
  formatter = dropdownFormatter,
  hideDeleted = false,
} = {}) {
  const value = useConfigOnce('products')
  const filtered = useMemo(
    () => (!hideDeleted ? value : omitBy(value, 'deleted')),
    [value, hideDeleted]
  )
  const formatted = useMemo(
    () => map(filtered, formatter),
    [filtered, formatter]
  )

  return {value, filtered, formatted}
}

// the component exist to get over the error
// that may arise when the dom in context is
// manipulated by some browser extension etc,
// and react would try to change the manipulated dom.
// use this component instead of the string text
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './index.module.scss'

const Text = ({id, children, className, breakAll}) => {
  if (!children) return null

  return (
    <span
      id={id}
      className={classNames(className, {
        [styles.breakAll]: breakAll,
      })}>
      {children}
    </span>
  )
}

Text.defaultProps = {
  breakAll: false,
}

Text.propTypes = {
  breakAll: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default Text

import some from 'lodash/some'
import endsWith from 'lodash/endsWith'

const PARTNER_URLS = [
  /\/dashboard\/?$/,
  /\/orders\/?$/,
  /\/orders\/[^\\/]+\/?$/,
  /\/jobs\/?$/,
  /\/tender-applications\/?$/,
  /\/invoices\/?$/,
  /\/invoices\/[^\\/]+\/?$/,
  /\/settings\/?$/,
  /\/settings\/[^\\/]+\/?$/,
]

export function isPathValidForRedirect(pathname) {
  return some(PARTNER_URLS, (url) => (pathname ? url.test(pathname) : false))
}

export function isFragmentUrl(pathname) {
  return endsWith(pathname, '/fragments') || endsWith(pathname, '/fragments/')
}

import React from 'react'
import PropTypes from 'prop-types'

import {Container} from 'reactstrap'
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react'

import {trackEvent, EVENTS} from 'modules/common/utils/analytics'

import LayoutHeader from './LayoutHeader'
import LayoutFooter from './LayoutFooter'

import './index.scss'

const Layout = ({user, router, onClickLogout, children, sidebarNavProps}) => (
  <div className="app">
    <AppHeader fixed>
      <LayoutHeader user={user} onClickLogout={onClickLogout} router={router} />
    </AppHeader>
    <div className="app-body">
      <AppSidebar fixed display="lg">
        <AppSidebarHeader />
        <AppSidebarForm />
        <AppSidebarNav
          router={router}
          {...sidebarNavProps}
          onClick={() => trackEvent(EVENTS.NAVBAR_CLICKED)}
        />
        <AppSidebarFooter />
        <AppSidebarMinimizer />
      </AppSidebar>
      <main className="main">
        <Container fluid>{children}</Container>
      </main>
    </div>
    <AppFooter>
      <Container className="text-center text-sm-left" fluid>
        <LayoutFooter />
      </Container>
    </AppFooter>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node,
  onClickLogout: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  sidebarNavProps: PropTypes.shape({
    navConfig: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
}

export default Layout

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {Col} from 'reactstrap'
import style from './Step.module.scss'

const Step = ({children, isActive, onClick, containerColumnProps}) => {
  return (
    <Col className="mx-auto text-center" {...containerColumnProps}>
      <div
        className={classNames(style.container, {
          [style.active]: isActive,
        })}>
        {children}
        <span
          className={classNames(style.icon, {
            [style.iconActive]: isActive,
            [style.clickable]: !!onClick,
          })}>
          <i className="fa fa-check fa-lg" />
        </span>
      </div>
    </Col>
  )
}

Step.defaultProps = {
  isActive: false,
  containerColumnProps: {},
}

Step.propTypes = {
  children: PropTypes.node,
  containerColumnProps: PropTypes.object,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Step

const errorColor = 'var(--danger)'
const errorStyles = {
  '&:hover': {borderColor: errorColor},
  border: `1px solid ${errorColor}`,
  boxShadow: 'none',
}
export const getCustomStyles = (hasError, styles = {}) => ({
  ...(styles || {}),
  control: (base, state) => ({
    ...base,
    ...(styles && styles.control ? styles.control(base, state) : {}),
    ...(!hasError ? {} : errorStyles),
  }),
})

export const getTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary)',
    primary75: 'var(--info)',
    primary50: 'var(--purple)',
    primary25: 'var(--purple)',
    danger: 'var(--danger)',
    dangerLight: 'var(--orange)',
  },
})

import {analytics} from 'configs/firebase'
import startsWith from 'lodash/startsWith'

import {CONTEXT} from './analyticsEvents'

export const trackEvent = (event, data) => {
  try {
    if (process.env.REACT_APP_LOG_ANALYTICS_EVENTS) {
      console.log('[Analytics] track event:', {event, data})
    }

    // only track in Segment events that starts with louver string
    if (startsWith(event, CONTEXT)) {
      typeof window !== 'undefined' &&
        window.analytics &&
        window.analytics.track(event, {...data})
    }

    analytics.logEvent(event, data)
  } catch (err) {
    console.warn('error logging analytics event', event, data)
  }
}

export const pageView = (url) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.page(url)

  if (process.env.REACT_APP_LOG_ANALYTICS_EVENTS) {
    console.log('[Analytics] page view:', url)
  }
}

export const identifyUser = (userId) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.identify(userId)

  analytics.setUserId(userId)

  if (process.env.REACT_APP_LOG_ANALYTICS_EVENTS) {
    console.log('[Analytics] identify:', {userId})
  }
}

export const identifyUserProperties = (userId, data) => {
  typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.identify(userId, data)

  analytics.setUserProperties(data)

  if (process.env.REACT_APP_LOG_ANALYTICS_EVENTS) {
    console.log('[Analytics] identify:', {userId, data})
  }
}

import get from 'lodash/get'
import some from 'lodash/some'
import isNil from 'lodash/isNil'

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export const isValidEmail = (email) => EMAIL_REGEX.test(email)

export const hasMissingFields = (obj, fieldsToCheck) =>
  some(fieldsToCheck, (field) => isNil(get(obj, field)))

import React, {useState, useEffect, useMemo} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import {Modal, ModalBody} from 'reactstrap'

import useCookie from 'hooks/useCookie'
import useToggleWithClickOutside from 'hooks/useToggleWithClickOutside'
import {gePartnerOnBoardingCookieNameByPartnerId} from 'modules/partners/utils/generalUtils'
import {
  hasMissingProfessions,
  hasMissingProducts,
} from 'modules/partners/utils/missingInfoUtils'
import PartnerOnboarding from 'modules/partners/components/partner/PartnerOnboarding'

// using cookie here as opposed to the local storage.
// this is to ensure that the on boarding popup is not shown
// when the user jumps back and forth between legacy app and the web 2.0
const PartnerOnboardingPopup = ({partner}) => {
  const partnerId = get(partner, 'uid')
  const [partnerOnBoardingCookie, setPartnerOnBoardingCookie] = useCookie(
    gePartnerOnBoardingCookieNameByPartnerId(partnerId),
    false
  )
  const [hasShownPopup, setHasShownPopup] = useState(!!partnerOnBoardingCookie)
  const {isOpen, close, toggle} = useToggleWithClickOutside(
    !partnerOnBoardingCookie
  )
  const hasMissingProfessionRelatedInfo = useMemo(
    () => hasMissingProfessions(partner) || hasMissingProducts(partner),
    [partner]
  )

  // set the partner on boarding cookie as soon as the component renders
  // this will allow all subsequent page navigations to suppress the popup
  useEffect(() => {
    if (hasShownPopup) return
    setHasShownPopup(true)
    setPartnerOnBoardingCookie(true)
  }, [hasShownPopup, setPartnerOnBoardingCookie])

  return (
    <Modal
      className="modal-full-width"
      isOpen={isOpen && hasMissingProfessionRelatedInfo}
      toggle={toggle}
      size="lg"
      backdrop="static"
      zIndex={99999}
      centered>
      <ModalBody>
        <PartnerOnboarding
          partner={partner}
          onClickSkip={close}
          onFinish={close}
        />
      </ModalBody>
    </Modal>
  )
}

PartnerOnboardingPopup.propTypes = {
  partner: PropTypes.object.isRequired,
}

export default PartnerOnboardingPopup

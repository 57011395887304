import React, {useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Label, FormGroup} from 'reactstrap'

import map from 'lodash/map'
import chunk from 'lodash/chunk'
import size from 'lodash/size'
import floor from 'lodash/floor'
import uniqueId from 'lodash/uniqueId'
import noop from 'lodash/noop'

import useFormField from 'modules/common/Form/hooks/useFormField'

import {CustomInput, FieldArray} from 'modules/common/Form'

import styles from './index.module.scss'

const CheckboxGroup = ({
  name,
  options,
  disabled,
  columnsCount,
  label,
  formGroupClassName,
  labelClassName,
  optionsContainerClassName,
  onChange,
}) => {
  const {
    field: {onChange: onChangeCheckboxGroup},
  } = useFormField({name, type: 'checkbox'})

  const optionsPerColumn = useMemo(
    () => size(options) / columnsCount,
    [options, columnsCount]
  )

  const optionsColumns = useMemo(
    () =>
      chunk(options, optionsPerColumn <= 1 ? 1 : floor(optionsPerColumn) + 1),
    [options, optionsPerColumn]
  )

  const labelField = label ? (
    <Label className={labelClassName}>{label}</Label>
  ) : null

  const handleCheckboxChange = useCallback(
    (e) => {
      onChange(e)
      onChangeCheckboxGroup(e)
    },
    [onChange, onChangeCheckboxGroup]
  )

  return (
    <FormGroup className={formGroupClassName}>
      {labelField}
      <div className={optionsContainerClassName}>
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <Row>
              {map(optionsColumns, (optionsColumn) => (
                <Col
                  key={uniqueId('options-column-')}
                  xs="12"
                  md={floor(12 / columnsCount)}>
                  {map(optionsColumn, (option) => (
                    <Row key={uniqueId(`option-${option.label}-`)}>
                      <Col>
                        <CustomInput
                          name={name}
                          type="checkbox"
                          value={option.value}
                          label={option.label}
                          disabled={disabled}
                          formGroupClassName={styles.checkBox}
                          onChange={handleCheckboxChange}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              ))}
            </Row>
          )}
        />
      </div>
    </FormGroup>
  )
}

CheckboxGroup.defaultProps = {
  columnsCount: 2,
  disabled: false,
  onChange: noop,
}

CheckboxGroup.propTypes = {
  columnsCount: PropTypes.number,
  disabled: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
    })
  ),
  optionsContainerClassName: PropTypes.string,
}

export default CheckboxGroup

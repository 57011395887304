import {auth} from 'configs/firebase'

export const signInWithEmailAndPassword = ({email, password}) =>
  auth.signInWithEmailAndPassword(email, password)

export const signOut = () => auth.signOut()

export const signInWithEmailLink = (email, link = window.location.href) =>
  auth.signInWithEmailLink(email, link)

export const isValidSignInWithEmailLink = (link = window.location.href) =>
  auth.isSignInWithEmailLink(link)

import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {isFragmentUrl} from 'modules/common/NewVersion/utils'

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  draggable: true,
  pauseOnHover: true,
})

export const notify = (...args) => toast(...args)

export const notifySuccess = (...args) => {
  if (isFragmentUrl(window.location.pathname)) {
    notifyLegacyApp({type: 'success', message: args[0]})
  } else {
    toast.success(...args)
  }
}

export const notifyError = (...args) => {
  if (isFragmentUrl(window.location.pathname)) {
    notifyLegacyApp({type: 'error', message: args[0]})
  } else {
    toast.error(...args)
  }
}

export const notifyWarn = (...args) => toast.warn(...args)
export const notifyInfo = (...args) => toast.info(...args)

export const notifyLegacyApp = (message) => {
  if ('parentIFrame' in window) {
    window.parentIFrame.sendMessage(message)
  }
}

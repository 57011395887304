import './polyfills'
import 'modules/common/utils/ErrorReporting'
import 'modules/common/utils/cookie-consent'
import React from 'react'
import ReactDOM from 'react-dom'
import './scss/style.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// include staging indicator on staging environment
if (process.env.REACT_APP_DEPLOYMENT_ENV === 'staging') {
  require('./visual-indicator')
}

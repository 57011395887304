import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import ENV from './env'

export const app = firebase.initializeApp(ENV.FIREBASE)
export const db = firebase.firestore(app)
export const auth = firebase.auth(app)
export const storage = firebase.storage(app).ref()
export const storageRoot = firebase.storage(app)
export const analytics = firebase.analytics(app)

export default firebase

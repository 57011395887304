import React from 'react'
import PropTypes from 'prop-types'

import {DropdownItem, DropdownMenu, DropdownToggle, Nav} from 'reactstrap'
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react'

import Logo from 'components/Logo'

const LayoutHeader = ({user, onClickLogout}) => (
  <>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand href="/">
      <Logo className="navbar-brand-full px-2" />
      <Logo type="icon" className="navbar-brand-minimized" />
    </AppNavbarBrand>
    <AppSidebarToggler className="d-md-down-none" display="lg" />

    <Nav className="ml-auto" navbar>
      <AppHeaderDropdown direction="down">
        <DropdownToggle nav>
          <img
            src={
              user.profileImgUrl ||
              `https://ui-avatars.com/api/?name=${encodeURIComponent(
                user.displayName
              )}&background=57c198&color=ffffff`
            }
            className="img-avatar"
            alt={user.email}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>{user.displayName || 'Account'}</strong>
          </DropdownItem>
          <DropdownItem onClick={onClickLogout}>
            <i className="fa fa-lock" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    </Nav>
  </>
)

LayoutHeader.propTypes = {
  onClickLogout: PropTypes.func,
  user: PropTypes.object,
}

export default LayoutHeader

import React from 'react'
import PropTypes from 'prop-types'
import {
  FormGroup,
  CustomInput as BaseCustomInput,
  FormFeedback,
} from 'reactstrap'

import useFormField from '../hooks/useFormField'

const CustomInput = ({formGroupClassName, ...props}) => {
  const type = props.type === 'switch' ? 'checkbox' : props.type
  const {
    id,
    hasError,
    isDisabled,
    field,
    meta: {error},
  } = useFormField({...props, type})

  return (
    <FormGroup className={formGroupClassName}>
      <BaseCustomInput
        {...field}
        {...props}
        id={id}
        disabled={isDisabled}
        invalid={hasError}
      />
      {hasError ? (
        <>
          <div className="custom-control-input is-invalid" />
          <FormFeedback>{error}</FormFeedback>
        </>
      ) : null}
    </FormGroup>
  )
}

CustomInput.propTypes = {
  formGroupClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default CustomInput

import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {useUserPermissions} from 'modules/auth/utils/UserContext'
import {isPathValidForRedirect} from './utils'

export function useShouldRedirect() {
  const location = useLocation()
  const {user, isAdmin} = useUserPermissions()

  return useMemo(
    () =>
      process.env.NODE_ENV === 'production' &&
      !!user &&
      !isAdmin &&
      isPathValidForRedirect(location.pathname),
    [user, isAdmin, location.pathname]
  )
}

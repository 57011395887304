import * as ErrorReporting from '@sentry/browser'

if (process.env.REACT_APP_DEPLOYMENT_ENV) {
  ErrorReporting.init({
    dsn: 'https://7b9440be5a13479b9aa54150d42f5d7d@sentry.io/1484776',
    environment: process.env.REACT_APP_DEPLOYMENT_ENV,
    // @see
    // https://stackoverflow.com/a/50387233
    // https://github.com/WICG/ResizeObserver/issues/38
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })
}

export default ErrorReporting

import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import Joyride from 'react-joyride'
import get from 'lodash/get'
import filter from 'lodash/filter'
import size from 'lodash/size'

import {db} from 'configs/firebase'
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore'

import withErrorBoundary from 'modules/common/utils/withErrorBoundary'
import {DEFAULT_LOCALE, DEFAULT_STYLES, DEFAULT_FLOATER_STYLES} from './const'
import {
  getTourStepsWithTargets,
  getTourSettings,
  getMarkStepShownFn,
  scrollToShownToolTip,
} from './utils'
import {useUserPermissions} from 'modules/auth/utils/UserContext'

const defaultStyles = {options: DEFAULT_STYLES}
const defaultFloaterProps = {
  styles: DEFAULT_FLOATER_STYLES,
  callback: scrollToShownToolTip,
}

/**
 * Use this component to render guided tours. The component internally
 * uses the external react-joyride library and adds the following
 * behaviours on top.
 * - shows only steps that have not been shown previously
 * - ability to define tour steps in the firestore database
 * - default styling and props as per our app theme
 * - error boundary in case the rendering fails
 *
 * @example
 * // first define the data in `guidedTours/orders-overview` firestore document
 * // with the keys as the helpKey and values as the map of step props
 * // ex: {filters: {title: 'Filters', content: 'These are filters'}}
 *
 * const targets = [
 *  {helpKey: 'filters', target: <HTMLNode|String>},
 *  //...
 * ]
 *
 * <GuidedTour
 *  tourId="orders-overview"
 *  targets={targets}
 *  forceRun={true} // if you want to show all steps irrespective of shown previously
 *  // ... other props from react-joyride
 * />
 */
const GuidedTour = ({tourId, forceRun, targets, callback, ...props}) => {
  const {loading: userIsLoading, isPartner} = useUserPermissions()
  const {
    error,
    loading,
    value: tourSteps,
  } = useDocumentDataOnce(
    !userIsLoading ? db.doc(`guidedTours/${tourId}`) : null
  )
  const tourSettings = useMemo(() => getTourSettings(tourId), [tourId])
  const markStepShown = useMemo(() => getMarkStepShownFn(tourId), [tourId])
  const steps = useMemo(
    () => getTourStepsWithTargets(tourSteps, targets),
    [tourSteps, targets]
  )
  const filteredSteps = useMemo(
    () =>
      filter(steps, (step) => {
        const helpKey = get(step, 'helpKey')
        if (!helpKey) return true

        return (
          forceRun ||
          get(step, 'forceRun', false) ||
          !get(tourSettings, `${helpKey}.hasRun`, false)
        )
      }),
    [tourSettings, steps, forceRun]
  )
  const run = forceRun || !!size(filteredSteps)

  const handleCallback = (props) => {
    markStepShown(props)
    callback && callback(props)
  }

  // do not show guided tour for partners
  // we would use some third party integrations like Appcues
  if (error || loading || userIsLoading || isPartner) return null

  return (
    <Joyride
      run={run}
      locale={DEFAULT_LOCALE}
      styles={defaultStyles}
      floaterProps={defaultFloaterProps}
      callback={handleCallback}
      steps={filteredSteps}
      continuous
      showSkipButton
      {...props}
    />
  )
}

GuidedTour.defaultProps = {
  forceRun: false,
}

GuidedTour.propTypes = {
  callback: PropTypes.func,
  forceRun: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object.isRequired),
  targets: PropTypes.arrayOf(
    PropTypes.shape({helpKey: PropTypes.string.isRequired})
  ),
  tourId: PropTypes.string.isRequired,
}

export default withErrorBoundary(GuidedTour, {
  errorComponent: () => null,
  reportError: false,
})

import React from 'react'
import {STATUS, ACTIONS, EVENTS} from 'react-joyride'
import each from 'lodash/each'
import get from 'lodash/get'
import merge from 'lodash/merge'
import {createStorage} from 'persistme'

import {scrollIframeElementIntoView} from 'utils/componentUtils'

const GuidedTourStorage = createStorage('construyo-guided-tour')

export const getTourSettings = (tourId) => GuidedTourStorage.get(tourId)

export const updateTourSettings = (tourId, data) =>
  GuidedTourStorage.update(tourId, merge(getTourSettings(tourId), data))

export const hasTourEnded = (status) =>
  status === STATUS.FINISHED || status === STATUS.SKIPPED

export const isStepShown = (props) =>
  get(props, 'action') === ACTIONS.UPDATE &&
  get(props, 'type') === EVENTS.TOOLTIP

export const getMarkStepShownFn = (tourId) => (props) => {
  const isCurrentStepShown = isStepShown(props)
  const helpKey = get(props, 'step.helpKey')
  if (isCurrentStepShown) {
    updateTourSettings(tourId, {[helpKey]: {hasRun: true}})
  }
}

export const getTourStepsWithTargets = (tourSteps, targets) => {
  const stepsWithTarget = []

  each(targets, (step) => {
    const helpKey = get(step, 'helpKey')
    const target = get(step, 'target')
    const tourStep = get(tourSteps, helpKey)
    if (tourStep && target) {
      const stepContent = get(tourStep, 'content', null)
      const content = (
        <div
          className="text-left"
          dangerouslySetInnerHTML={{__html: stepContent}}
        />
      )
      stepsWithTarget.push({
        placement: 'auto',
        placementBeacon: 'top',
        ...tourStep,
        content,
        target,
        helpKey,
      })
    }
  })

  return stepsWithTarget
}

// the native scrolling from the react-joyride library will not
// work in case our app is hosted inside an iframe, like
// the order details page. as such we need to force scroll
// the element into view using the `parentIFrame`.
// @TODO: remove this once we have migrated the legacy app
export const scrollToShownToolTip = (action) => {
  if (action === 'open') {
    const shownTooltipElement = get(
      document.querySelectorAll('.__floater.__floater__open'),
      '0',
      null
    )
    if (shownTooltipElement) {
      scrollIframeElementIntoView(shownTooltipElement)
    }
  }
}

import get from 'lodash/get'
import pick from 'lodash/pick'

import {ONBOARDING_COOKIE_NAME} from './const'

export const getPartnerLocationObject = (partner) =>
  get(partner, '_geoloc') || pick(get(partner, 'address'), ['lat', 'lng'])

export const isValidLocationObject = (loc) =>
  loc && isFinite(loc.lat) && isFinite(loc.lng)

export const getValidPartnerLocationObjectOrNull = (partner) => {
  const loc = getPartnerLocationObject(partner)
  return isValidLocationObject(loc) ? loc : null
}

export const gePartnerOnBoardingCookieNameByPartnerId = (partnerId) =>
  `${ONBOARDING_COOKIE_NAME}${partnerId}`

import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'

export default [
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/partners/:partnerId/fragments/compliance-documents',
    component: lazy(() =>
      import('./fragments/PartnerComplianceDocumentsFragment')
    ),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners/map',
    component: lazy(() => import('./PartnersMapOverview')),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners/list',
    component: lazy(() => import('./PartnersOverview')),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners/new',
    component: lazy(() => import('./PartnerCreate')),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners/:partnerId/admin',
    component: lazy(() => import('./PartnerAdmin')),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners/:partnerId',
    component: lazy(() => import('./PartnerDetails')),
  },
  {
    isProtected: true,
    exact: true,
    path: '/partners',
    component: () => <Redirect to="/partners/list" />,
  },
  // @TODO:
  // the `/settings` path here should ideally be moved to the common route
  // as it does not correspond to the partners module specific route.
  // since we do not have a setup for the common routes just yet, for now
  // it's alright to have the path enlisted here, but in future
  // this could be any user context related settings page.
  {
    isProtected: true,
    exact: true,
    path: '/settings',
    component: lazy(() => import('./PartnerSettings')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/settings/overview/fragments',
    component: lazy(() => import('./PartnerSettings')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/settings/:activeTab/fragments',
    component: lazy(() => import('./PartnerSettings')),
  },
]

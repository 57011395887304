import get from 'lodash/get'
import {hasMissingFields} from 'utils/validationUtils'
import size from 'lodash/size'

export const hasMissingDocuments = (partner) =>
  !!get(partner, 'meta.missingDocs', false)

export const hasMissingBasicInformation = (partner) =>
  hasMissingFields(partner, [
    'address.street',
    'address.zip',
    'address.city',
    'contact.person',
    'contact.email',
    'contact.phone',
  ])

export const hasMissingInvoiceDetails = (partner) =>
  hasMissingFields(partner, [
    'invoiceDetails.companyName',
    'invoiceDetails.street',
    'invoiceDetails.zip',
    'invoiceDetails.city',
    'invoiceDetails.taxId',
    'invoiceDetails.owner',
    'invoiceDetails.bankName',
    'invoiceDetails.iban',
    'invoiceDetails.bic',
  ])

export const hasMissingProfessions = (partner) =>
  !size(get(partner, 'services'))

export const hasMissingProducts = (partner) => !size(get(partner, 'products'))

export const hasMissingDetails = (partner) =>
  hasMissingDocuments(partner) ||
  hasMissingBasicInformation(partner) ||
  hasMissingInvoiceDetails(partner) ||
  hasMissingProfessions(partner) ||
  hasMissingProducts(partner)

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useFormikContext as useFormContext} from 'formik'
import noop from 'lodash/noop'

const ValuesChangeSubscriber = ({onChange}) => {
  const {values} = useFormContext()

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  return <></>
}

ValuesChangeSubscriber.defaultProps = {
  onChange: noop,
}

ValuesChangeSubscriber.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default ValuesChangeSubscriber

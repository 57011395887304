import {USER_ROLES} from 'modules/auth/utils/const'
import {lazy} from 'react'

export default [
  {
    isProtected: true,
    exact: true,
    path: '/invoices/new/:orderId',
    component: lazy(() => import('./InvoiceCreation')),
    roles: [USER_ROLES.FINANCE],
  },
  {
    isProtected: true,
    exact: true,
    path: '/invoices/import/:orderId',
    component: lazy(() => import('./InvoiceImport')),
    roles: [USER_ROLES.FINANCE],
  },
  {
    isProtected: true,
    exact: true,
    path: '/invoices/:invoiceId',
    component: lazy(() => import('./InvoiceDetails')),
    roles: [USER_ROLES.FINANCE, USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/invoices/overview/fragments',
    component: lazy(() => import('./InvoicesOverview')),
    roles: [USER_ROLES.FINANCE, USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/invoices/:invoiceId/fragments',
    component: lazy(() => import('./InvoiceDetails')),
    roles: [USER_ROLES.FINANCE, USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    path: '/invoices',
    component: lazy(() => import('./InvoicesOverview')),
    roles: [USER_ROLES.FINANCE, USER_ROLES.PARTNER],
  },
  {
    isProtected: true,
    exact: true,
    path: '/invoices/:invoiceId/edit',
    component: lazy(() => import('./InvoiceEdit')),
    roles: [USER_ROLES.FINANCE],
  },
]

import React from 'react'
import PropTypes from 'prop-types'

import {Row, Col, Button} from 'reactstrap'

import {useStepper} from './context'

const Toolbar = ({
  nextBtnLabel,
  previousBtnLabel,
  finishBtnLabel,
  isPreviousDisabled,
  isNextDisabled,
  isFinishDisabled,
  children,
  onClickFinish,
}) => {
  const {currentStep, isFinalStep, previous, next} = useStepper()

  return (
    <Row>
      <Col>
        <div className="pull-right">
          {children}
          <Button
            outline
            className="mx-2"
            color="success"
            disabled={currentStep === 0 || isPreviousDisabled}
            onClick={previous}>
            {previousBtnLabel}
          </Button>
          {!isFinalStep ? (
            <Button color="success" disabled={isNextDisabled} onClick={next}>
              {nextBtnLabel}
            </Button>
          ) : (
            <Button
              color="success"
              disabled={isFinishDisabled}
              onClick={onClickFinish}>
              {finishBtnLabel}
            </Button>
          )}
        </div>
      </Col>
    </Row>
  )
}

Toolbar.defaultProps = {
  finishBtnLabel: 'Beenden',
  nextBtnLabel: 'Nächster',
  previousBtnLabel: 'Zurück',
  isPreviousDisabled: false,
  isNextDisabled: false,
  isFinishDisabled: false,
}

Toolbar.propTypes = {
  children: PropTypes.node,
  finishBtnLabel: PropTypes.node,
  isFinishDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isPreviousDisabled: PropTypes.bool,
  nextBtnLabel: PropTypes.node,
  onClickFinish: PropTypes.func,
  previousBtnLabel: PropTypes.node,
}

export default Toolbar

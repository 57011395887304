export const convertToLocaleString = (amount) =>
  amount === null || !Number.isFinite(amount)
    ? '0,00'
    : amount
        .toFixed(2)
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const roundedNumber = (number) => Math.round(number * 100) / 100

export const roundedPercentage = (percentage, digits = 0) =>
  roundedNumber(percentage * 100).toFixed(digits)

export const roundedPercentageString = (percentage, digits = 0) =>
  `${roundedPercentage(percentage, digits)}%`

export const calculatePriceWithVat = ({basePrice, vat, percentage = 1}) =>
  roundedNumber((basePrice + basePrice * vat) * percentage)

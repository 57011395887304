import {useMemo, useRef} from 'react'
import useOnClickOutside from 'use-onclickoutside'
import useToggle from './useToggle'

export default function useToggleWithClickOutside(defaultIsOpen = false) {
  const ref = useRef(null)
  const {id, isOpen, open, close, toggle} = useToggle(defaultIsOpen)

  useOnClickOutside(ref, close)

  return useMemo(
    () => ({id, ref, isOpen, open, close, toggle}),
    [id, ref, isOpen, open, close, toggle]
  )
}

/* eslint-disable filenames/match-exported */
import React, {Suspense, lazy} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Loader from 'components/Loader'
import ProtectedRoute from 'modules/auth/utils/ProtectedRoute'
import Layout from 'modules/common/Layout'
import PageView from 'modules/common/PageView'

import authRoutes from 'modules/auth/routes'
import customerRoutes from 'modules/customers/routes'
import offersRoutes from 'modules/offers/routes'
import ordersRoutes from 'modules/orders/routes'
import jobsRoutes from 'modules/jobs/routes'
import partnersRoutes from 'modules/partners/routes'
import invoicesRoutes from 'modules/invoices/routes'
import dashboardRoutes from 'modules/dashboard/routes'
import marketplaceRoutes from 'modules/marketplace/routes'
import usersRoutes from 'modules/users/routes'

const NotFoundPage = lazy(() => import('modules/common/Errors/NotFound'))

const routes = [].concat(
  customerRoutes,
  offersRoutes,
  ordersRoutes,
  jobsRoutes,
  partnersRoutes,
  invoicesRoutes,
  dashboardRoutes,
  marketplaceRoutes,
  usersRoutes
)

// enable the login page in dev mode
// to be able to login to the app
if (process.env.NODE_ENV !== 'production') {
  routes.push(...authRoutes)
}

const routesWithDefaultLayout = {
  path: [],
  items: [],
}

const routesWithCustomLayout = {
  path: [],
  items: [],
}

routes.forEach(
  (
    {exact, path, isProtected, customLayout, roles, component: Component},
    index
  ) => {
    const RouteComponent = isProtected ? ProtectedRoute : Route
    const props = {
      component: Component,
      key: path || index,
      hasCustomLayout: !!customLayout,
      exact,
      path,
      roles,
    }

    if (!customLayout) {
      routesWithDefaultLayout.path.push(path)
      routesWithDefaultLayout.items.push(<RouteComponent {...props} />)
    } else {
      routesWithCustomLayout.path.push(path)
      routesWithCustomLayout.items.push(<RouteComponent {...props} />)
    }
  }
)

const DefaultLayoutRouteSwitch = () => (
  <Layout>
    <PageView>
      <Switch>{routesWithDefaultLayout.items}</Switch>
    </PageView>
  </Layout>
)

const CustomLayoutRouteSwitch = () => (
  <PageView>
    <Switch>{routesWithCustomLayout.items}</Switch>
  </PageView>
)

const Routes = () => (
  <Suspense fallback={<Loader />}>
    <Router>
      <Switch>
        <Route
          exact
          path={routesWithDefaultLayout.path}
          component={DefaultLayoutRouteSwitch}
        />
        <Route
          exact
          path={routesWithCustomLayout.path}
          component={CustomLayoutRouteSwitch}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  </Suspense>
)

export default Routes

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ErrorReporting from 'modules/common/utils/ErrorReporting'
import InternalErrorPage from './InternalError'

const CHUNK_FAILED_MESSAGE_REGEX = /Loading chunk [\d]+ failed./

export default class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    const errorState = {hasError: true, errorTitle: error.title}

    if (error.customMessage) {
      errorState.errorMessage = error.customMessage
    }

    return errorState
  }

  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    if (CHUNK_FAILED_MESSAGE_REGEX.test(error.message || '')) {
      return window.location.reload()
    }

    ErrorReporting.withScope((scope) => {
      scope.setExtras(info)
      const eventId = ErrorReporting.captureException(error)
      this.setState({eventId})
    })
  }

  render() {
    const {hasError, errorMessage, errorTitle, eventId} = this.state

    if (hasError) {
      return (
        <InternalErrorPage
          title={errorTitle}
          text={errorMessage}
          errorId={eventId}
        />
      )
    }

    return this.props.children
  }
}

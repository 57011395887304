import React, {useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import defaultsDeep from 'lodash/defaultsDeep'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import classNames from 'classnames'
import {FormGroup, Label, FormFeedback} from 'reactstrap'
import BaseDatePicker from 'components/DatePicker'

import useFormField from '../hooks/useFormField'
import {parseDate, isValid} from 'utils/dateUtils'

const DatePicker = ({
  label,
  labelClassName,
  formGroupClassName,
  showErrorMessage,
  ...props
}) => {
  const {
    id,
    hasError,
    isDisabled,
    field,
    helpers,
    meta: {error},
  } = useFormField(props)

  const formattedValue = useMemo(
    () => (field.value && field.value !== -1 ? parseDate(field.value) : null),
    [field.value]
  )

  const labelField = label ? (
    <Label htmlFor={id} className={labelClassName}>
      {label}
    </Label>
  ) : null

  const inputPropsClassName = useMemo(
    () =>
      classNames({'is-invalid': hasError}, get(props, 'inputProps.className')),
    [props, hasError]
  )

  const inputProps = useMemo(
    () => ({
      className: inputPropsClassName,
      disabled: isDisabled,
    }),
    [isDisabled, inputPropsClassName]
  )

  const computedInputProps = useMemo(
    () => defaultsDeep(inputProps, props.inputProps),
    [props, inputProps]
  )

  const handleOnChange = useCallback(
    (date) => {
      const formattedDate =
        date && isValid(date) ? date.getTime() : isNil(date) ? null : -1
      helpers.setValue(formattedDate, true)
    },
    [helpers]
  )

  return (
    <FormGroup className={formGroupClassName}>
      {labelField}
      <BaseDatePicker
        {...field}
        {...props}
        id={id}
        value={formattedValue}
        onDayChange={handleOnChange}
        inputProps={computedInputProps}
      />
      {hasError && showErrorMessage ? (
        <>
          <div className="custom-control-input is-invalid" />
          <FormFeedback>{error}</FormFeedback>
        </>
      ) : null}
    </FormGroup>
  )
}

DatePicker.defaultProps = {
  showErrorMessage: true,
}

DatePicker.propTypes = {
  formGroupClassName: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  showErrorMessage: PropTypes.bool,
}

export default DatePicker

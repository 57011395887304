import React, {useState, useCallback, useContext} from 'react'
import PropTypes from 'prop-types'
import inRange from 'lodash/inRange'
import noop from 'lodash/noop'

const StepperContext = React.createContext({
  currentStep: -1,
  totalSteps: 0,
  isFinalStep: false,
  previous: noop,
  next: noop,
  goToStep: noop,
})

export const useStepper = () => useContext(StepperContext)

const StepperProvider = ({children, initialStep, totalSteps}) => {
  const [currentStep, setCurrentStep] = useState(
    inRange(initialStep, totalSteps) ? initialStep : 0
  )
  const previous = useCallback(
    () => setCurrentStep((current) => Math.max(0, current - 1)),
    []
  )
  const next = useCallback(
    () => setCurrentStep((current) => Math.min(totalSteps - 1, current + 1)),
    [totalSteps]
  )
  const goToStep = useCallback(
    (step) =>
      setCurrentStep((current) => (inRange(step, totalSteps) ? step : current)),
    [totalSteps]
  )
  const isFinalStep = currentStep === totalSteps - 1

  return (
    <StepperContext.Provider
      value={{currentStep, totalSteps, isFinalStep, previous, next, goToStep}}>
      {children}
    </StepperContext.Provider>
  )
}

StepperProvider.defaultProps = {
  initialStep: 0,
  totalSteps: 0,
}

StepperProvider.propTypes = {
  children: PropTypes.node,
  initialStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
}

export {StepperProvider}

import {useCallback, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import noop from 'lodash/noop'

import useBeforeUnload from 'hooks/useBeforeUnload'

// @TODO:
// the `Prompt` component from `react-router-dom` does not work
// as expected, even though the navigation is blocked, the browser
// url still changes when using the back button.
// @see https://github.com/ReactTraining/react-router/issues/5405

/**
 * `useNavigationPrompt` can be used to block the navigation based on
 * the provided `when` condition. An optional `message` can be passed.
 *
 * @param {Boolean} when The condition when to show the prompt
 * @param {String} message The message to show for the prompt
 */
const useNavigationPrompt = (when, message) => {
  const handleBeforeUnload = useCallback(
    (ev) => {
      if (when) {
        ev.preventDefault()
        return message
      }
    },
    [when, message]
  )
  useBeforeUnload(handleBeforeUnload)

  const history = useHistory()
  useEffect(() => {
    let unBlock = noop
    if (when) {
      unBlock = history.block(message)
    }
    return unBlock
  })
}

export default useNavigationPrompt

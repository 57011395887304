import {lazy} from 'react'
import {USER_ROLES} from 'modules/auth/utils/const'

export default [
  {
    isProtected: true,
    exact: true,
    path: '/users',
    component: lazy(() => import('./UsersOverview')),
    roles: [USER_ROLES.ADMIN],
  },
  {
    isProtected: true,
    exact: true,
    path: '/users/:userId',
    component: lazy(() => import('./UserDetails')),
    roles: [USER_ROLES.ADMIN],
  },
]

import React from 'react'
import PropTypes from 'prop-types'

const TelLink = ({phone, showIcon}) =>
  phone ? (
    <>
      {showIcon && <i className="icon-phone align-middle" />}{' '}
      <a href={`tel:${phone}`}>{phone}</a>{' '}
    </>
  ) : null

TelLink.defaultProps = {
  showIcon: false,
}

TelLink.propTypes = {
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showIcon: PropTypes.bool,
}

export default TelLink

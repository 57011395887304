import deLocale from 'date-fns/locale/de'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isPast from 'date-fns/is_past'
import isSameDay from 'date-fns/is_same_day'
import endOfDay from 'date-fns/end_of_day'
import startOfDay from 'date-fns/start_of_day'
import subDays from 'date-fns/sub_days'
import addDays from 'date-fns/add_days'
import isValid from 'date-fns/is_valid'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import isWeekend from 'date-fns/is_weekend'
import isSaturday from 'date-fns/is_saturday'
import isSunday from 'date-fns/is_sunday'
import differenceInHours from 'date-fns/difference_in_hours'

import toInteger from 'lodash/toInteger'

export const formatDate = (unixTimeStamp, dateFormat = 'DD.MM.YYYY') =>
  format(parse(unixTimeStamp), dateFormat, {locale: deLocale})

export const isExpiredDate = (unixTimeStamp) => {
  const date = parse(unixTimeStamp)
  return isPast(date) && !isSameDay(date, Date.now())
}

export const isLessThanTwentyFourHours = (unixTimeStamp) => {
  const date = parse(unixTimeStamp)
  const hours = differenceInHours(Date.now(), date)
  return hours < 24
}

export const WEEK = 1000 * 60 * 60 * 24 * 7

export const DAY = 1000 * 60 * 60 * 24

export const toWeeks = (mil) => Math.floor(mil / WEEK)

export const endOfToday = () => endOfDay(new Date())

export const startOfToday = () => startOfDay(new Date())

export {parse as parseDate}
export {endOfDay, startOfDay, subDays, addDays, isValid}

export const differenceInBusinessDays = (
  receivedDateLeft,
  receivedDateRight
) => {
  const dateLeft = parse(receivedDateLeft)
  let dateRight = parse(receivedDateRight)

  if (!isValid(dateLeft) || !isValid(dateRight)) return new Date(NaN)

  const calendarDifference = differenceInCalendarDays(dateLeft, dateRight)
  const sign = calendarDifference < 0 ? -1 : 1

  const weeks = toInteger(calendarDifference / 7)

  let result = weeks * 5
  dateRight = addDays(dateRight, weeks * 7)

  // the loop below will run at most 6 times to account for the remaining days that don't makeup a full week
  while (!isSameDay(dateLeft, dateRight)) {
    // sign is used to account for both negative and positive differences
    result += isWeekend(dateRight) ? 0 : sign
    dateRight = addDays(dateRight, sign)
  }

  return result === 0 ? 0 : result
}

export const addBusinessDays = (receivedDate, amount) => {
  const date = parse(receivedDate)
  const startedOnWeekend = isWeekend(date)

  const hours = date.getHours()
  const sign = amount < 0 ? -1 : 1
  const fullWeeks = toInteger(amount / 5)

  date.setDate(date.getDate() + fullWeeks * 7)

  // Get remaining days not part of a full week
  let restDays = Math.abs(amount % 5)

  // Loops over remaining days
  while (restDays > 0) {
    date.setDate(date.getDate() + sign)
    if (!isWeekend(date)) restDays -= 1
  }

  // If the date is a weekend day and we reduce a dividable of
  // 5 from it, we land on a weekend date.
  // To counter this, we add days accordingly to land on the next business day
  if (startedOnWeekend && isWeekend(date) && amount !== 0) {
    // If we're reducing days, we want to add days until we land on a weekday
    // If we're adding days we want to reduce days until we land on a weekday
    if (isSaturday(date)) date.setDate(date.getDate() + (sign < 0 ? 2 : -1))
    if (isSunday(date)) date.setDate(date.getDate() + (sign < 0 ? 1 : -2))
  }

  // Restore hours to avoid DST lag
  date.setHours(hours)

  return date
}

export const TIME_UNIT_WEEKS = 'WEEKS'

export const TIME_UNIT_DAYS = 'DAYS'

export function convertTimestampToTimeUnit(timestamp) {
  if (timestamp % WEEK === 0) {
    return {number: timestamp / WEEK, timeUnit: TIME_UNIT_WEEKS}
  }

  return {number: timestamp / DAY, timeUnit: TIME_UNIT_DAYS}
}

import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {Col, Container, Row} from 'reactstrap'

import Logo from 'components/Logo'
import Help from 'components/Help'

const InternalError = ({
  pageTitle,
  text,
  title,
  errorId,
  hideLogo,
  containerClassName,
}) => (
  <div className={containerClassName}>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
          <div className="clearfix">
            {!hideLogo && (
              <p>
                <Logo />
              </p>
            )}
            <h1 className="float-left display-3 mr-4 d-none d-md-block">500</h1>
            <h4 className="pt-3">{title}</h4>
            <p className="float-left">{text}</p>
            {errorId && (
              <p className="float-left mr-2" style={{wordBreak: 'break-all'}}>
                Fehlercode: <br />
                {errorId}
              </p>
            )}
            <p className="float-left">
              <Help />
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
)

InternalError.defaultProps = {
  text: 'Während Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.',
  title: 'Oops!',
  hideLogo: false,
  containerClassName: 'app flex-row align-items-center',
  pageTitle: 'Interner Fehler',
}

InternalError.propTypes = {
  containerClassName: PropTypes.string,
  errorId: PropTypes.string,
  hideLogo: PropTypes.bool,
  pageTitle: PropTypes.string,
  text: PropTypes.node,
  title: PropTypes.node,
}

export default InternalError

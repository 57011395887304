import React from 'react'
import PropTypes from 'prop-types'

import {Row} from 'reactstrap'

const StepProgress = ({children}) => {
  return <Row className="justify-content-center no-gutters">{children}</Row>
}

StepProgress.propTypes = {
  children: PropTypes.node,
}

export default StepProgress

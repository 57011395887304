import React from 'react'
import PropTypes from 'prop-types'

import {Row, Col} from 'reactstrap'

import PartnerProducts from 'modules/partners/components/partner/PartnerForm/PartnerProducts'

const Products = ({partner}) => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <h5>
            Bitte wählen Sie sämtliche Leistungen aus, die Ihr Unternehmen
            erbringen kann.
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <PartnerProducts partner={partner} hideDeleted />
        </Col>
      </Row>
    </>
  )
}

Products.propTypes = {
  partner: PropTypes.object,
}

export default Products

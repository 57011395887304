import 'iframe-resizer/js/iframeResizer.contentWindow.min'

import get from 'lodash/get'

// for now this function doesn't do much
// except for the fact that we have the
// iframe re-sizer loaded at the top
// however in future this could be used for
// generating some hoc for web components etc
// in order to provide better integration
// with the legacy application
export function makeEmbeddableFragment(component) {
  return component
}

export function scrollIframeIntoView() {
  if ('parentIFrame' in window) {
    window.parentIFrame.getPageInfo(({iframeHeight}) => {
      const xAxis = 0
      const yAxis = iframeHeight / 2 - iframeHeight / 12
      window.parentIFrame.scrollTo(xAxis, yAxis)
      window.parentIFrame.getPageInfo(false)
    })
  }
}

export function scrollIframeElementIntoView(element) {
  if ('parentIFrame' in window && element) {
    window.parentIFrame.getPageInfo(() => {
      const domRect =
        element.getBoundingClientRect && element.getBoundingClientRect()
      const elementHeight = get(domRect, 'height', 0)
      const elementTop = get(domRect, 'top', 0)
      const xAxis = 0
      const yAxis = elementTop - elementHeight / 2
      window.parentIFrame.scrollToOffset(xAxis, yAxis)
      window.parentIFrame.getPageInfo(false)
    })
  }
}

export function scrollNodeToBottom(node) {
  window.requestAnimationFrame(
    () => node && (node.scrollTop = node.scrollHeight)
  )
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export function preventDefault(ev) {
  ev && ev.preventDefault()
}

export function stopPropagation(ev) {
  ev && ev.stopPropagation()
}

export function reload() {
  window.location.reload()
}

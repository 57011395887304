import {useCallback, useMemo, useState} from 'react'
import useId from './useId'

export default function useToggle(defaultIsOpen = false) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const id = useId()

  const close = useCallback(() => setIsOpen(false), [])
  const open = useCallback(() => setIsOpen(true), [])
  const toggle = useCallback(() => setIsOpen((prev) => !prev), [])

  return useMemo(
    () => ({id, isOpen, open, close, toggle}),
    [id, isOpen, open, close, toggle]
  )
}

import get from 'lodash/get'
import compact from 'lodash/compact'
import {auth} from 'configs/firebase'

import {USER_ROLES} from './const'

export const getCurrentUser = () => auth.currentUser

export const getCurrentUserId = () => get(getCurrentUser(), 'uid', null)

export const getIdToken = async () => {
  const currentUser = getCurrentUser()

  if (!currentUser) return null

  let idToken = null
  try {
    idToken = await currentUser.getIdToken()
  } catch (err) {
    // log errors??
  }
  return idToken
}

export const getIdTokenResult = async () => {
  const currentUser = getCurrentUser()

  if (!currentUser) return null

  let idTokenResult = null
  try {
    idTokenResult = await currentUser.getIdTokenResult(true)
  } catch (err) {
    // log errors??
  }
  return idTokenResult
}

export const getAuthorizationHeaderObject = async () => {
  const idToken = await getIdToken()

  if (!idToken) return {}

  return {authorization: `Bearer ${idToken}`}
}

export const isUserEnabled = (user) => !!get(user, 'permissions.isEnabled')

export const isUserAPartner = (user) => !!get(user, 'partnerId', false)
export const isUserAnAdmin = (user) => !!get(user, 'permissions.admin', false)
export const isUserASuperUser = (user) => !!get(user, 'permissions.su', false)
// there is no sales or ops role implementation yet
// add appropriate checks when implemented
export const isUserASalesAgent = (user) => isUserAnAdmin(user)
export const isUserAnOpsAgent = (user) => isUserAnAdmin(user)
export const isUserAFinanceAgent = (user) => isUserAnAdmin(user)

export const getUserRoles = (user) => {
  if (!isUserEnabled(user)) return []

  return compact([
    USER_ROLES.USER,
    isUserAPartner(user) && USER_ROLES.PARTNER,
    isUserASalesAgent(user) && USER_ROLES.SALES,
    isUserAnOpsAgent(user) && USER_ROLES.OPS,
    isUserAnAdmin(user) && USER_ROLES.ADMIN,
    isUserASuperUser(user) && USER_ROLES.SUPER_USER,
    isUserAFinanceAgent(user) && USER_ROLES.FINANCE,
  ])
}

// we are using the custom claims for customer auth.
// ideally all the user permissions should have been
// setup using the custom claims initially,
// however we do not have such a setup just yet
export const isCurrentUserACustomer = async () =>
  !!get(await getIdTokenResult(), 'claims.customer', false)

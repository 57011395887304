import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import {UncontrolledTooltip} from 'reactstrap'
import {useFormikContext} from 'formik'

import {calculatePriceWithVat, convertToLocaleString} from 'utils/currencyUtils'
import useId from 'hooks/useId'
import Input from '../Input'

const InputWithVatDisplay = ({vat, ...props}) => {
  const {values} = useFormikContext()
  const domId = useId()
  const fieldId = useMemo(() => props.id || domId, [props.id, domId])
  const fieldName = useMemo(() => get(props, 'name'), [props])
  const fieldValue = useMemo(() => get(values, fieldName), [values, fieldName])

  const valueWithVat = useMemo(
    () =>
      fieldValue && vat
        ? calculatePriceWithVat({basePrice: fieldValue, vat})
        : null,
    [vat, fieldValue]
  )

  const formattedValueWithVat = useMemo(
    () => (valueWithVat ? convertToLocaleString(valueWithVat) : null),
    [valueWithVat]
  )

  return (
    <>
      <Input {...props} id={fieldId} type="number" />
      <UncontrolledTooltip
        target={fieldId}
        trigger={props.disabled ? 'hover click' : 'focus'}
        autohide={false}>
        {formattedValueWithVat ? `Brutto: ${formattedValueWithVat} EUR` : '---'}
      </UncontrolledTooltip>
    </>
  )
}

InputWithVatDisplay.defaultProps = {}

InputWithVatDisplay.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  vat: PropTypes.number,
}

export default InputWithVatDisplay

import React from 'react'
import {Helmet} from 'react-helmet'
import {GraphQLProvider} from 'modules/common/utils/GraphQLContext'
import {UserProvider} from 'modules/auth/utils/UserContext'
import {PartnersProvider} from 'modules/common/utils/PartnersContext'

import ErrorBoundary from 'modules/common/Errors/ErrorBoundary'
import Routes from './routes'

function App() {
  return (
    <>
      <Helmet titleTemplate="%s | Construyo" defaultTitle="Construyo" />
      <GraphQLProvider>
        <UserProvider>
          <PartnersProvider>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </PartnersProvider>
        </UserProvider>
      </GraphQLProvider>
    </>
  )
}

export default App

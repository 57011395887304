import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {getDisplayName} from 'utils/componentUtils'
import ErrorReporting from 'modules/common/utils/ErrorReporting'
import Text from 'components/Text'

export default function withErrorBoundary(
  WrappedComponent,
  {errorComponent: ErrorComponent = Text, reportError = true} = {}
) {
  return class WithErrorBoundaryComponent extends Component {
    static displayName = `withErrorBoundary(${getDisplayName(
      WrappedComponent
    )})`

    static propTypes = {
      children: PropTypes.node,
    }

    state = {
      hasError: false,
      error: null,
      info: null,
      eventId: null,
    }

    componentDidCatch(error, info) {
      const errorState = {hasError: true, error, info}

      this.setState(errorState)

      if (reportError) {
        ErrorReporting.withScope((scope) => {
          scope.setExtras(info)
          const eventId = ErrorReporting.captureException(error)
          this.setState({eventId})
        })
      }
    }

    render() {
      const {hasError} = this.state

      if (hasError) {
        return <ErrorComponent {...this.state} />
      }

      return <WrappedComponent {...this.props} />
    }
  }
}

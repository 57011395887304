import React from 'react'
import PropTypes from 'prop-types'

import {Row, Col} from 'reactstrap'
import get from 'lodash/get'

import PartnerServices from 'modules/partners/components/partner/PartnerForm/PartnerServices'

const Professions = ({partner}) => {
  const partnerName = get(partner, 'contact.person') || get(partner, 'name', '')

  return (
    <>
      <p className="px-4 mb-4">
        Sehr geehrte(r) {partnerName}
        <br />
        <br />
        um Ihnen relevante Projekte vorschlagen zu können, benötigen wir
        folgende Informationen:
      </p>
      <Row className="mb-4">
        <Col>
          <h5>Welche Berufe sind in Ihrem Unternehmen vertreten?</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <PartnerServices partner={partner} />
        </Col>
      </Row>
    </>
  )
}

Professions.propTypes = {
  partner: PropTypes.object,
}

export default Professions

import React from 'react'
import PropTypes from 'prop-types'

import {Row, Col} from 'reactstrap'
import {CheckboxGroup} from 'modules/common/Form'

import size from 'lodash/size'
import get from 'lodash/get'

import {PROFESSIONS} from 'modules/partners/utils/const'
import Outline from 'components/Outline'
import MissingDetailsBadge, {
  hasMissingDetailsForPartner,
} from 'modules/partners/components/partner/PartnerMetaBadges/MissingDetailsBadge'

import styles from './index.module.scss'

const PartnerServices = ({
  labelClassName,
  disabled,
  showOutlineForMissingDetails,
  partner,
}) => {
  const hasProfessions = size(get(partner, 'services'))

  const showOutline =
    showOutlineForMissingDetails && hasMissingDetailsForPartner(partner)
  const missingDetailsBadgeNode = showOutline ? (
    <MissingDetailsBadge partner={partner} />
  ) : null

  return (
    <>
      <Outline
        show={showOutline && !hasProfessions}
        badge={missingDetailsBadgeNode}>
        <Row>
          <Col>
            <CheckboxGroup
              name="services"
              options={PROFESSIONS}
              label="Professionen"
              labelClassName={labelClassName}
              disabled={disabled}
              optionsContainerClassName={styles.optionsContainer}
              columnsCount={4}
            />
          </Col>
        </Row>
      </Outline>
    </>
  )
}

PartnerServices.defaultProps = {
  disabled: false,
  showOutlineForMissingDetails: false,
  labelClassName: 'font-weight-bold',
}

PartnerServices.propTypes = {
  disabled: PropTypes.bool,
  labelClassName: PropTypes.string,
  partner: PropTypes.object,
  showOutlineForMissingDetails: PropTypes.bool,
}

export default PartnerServices

import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import {USER_ROLES} from 'modules/auth/utils/const'

export default [
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/marketplace',
    component: lazy(() => import('./MarketplaceDetails')),
    roles: [USER_ROLES.SALES],
  },
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/marketplace/new',
    component: lazy(() => import('./MarketplaceDetails')),
    roles: [USER_ROLES.SALES],
  },
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/marketplace/update',
    component: lazy(() => import('./MarketplaceDetails')),
    roles: [USER_ROLES.SALES],
  },
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/tenders',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/opportunity/${props.match.params.opportunityId}/marketplace`}
      />
    ),
    roles: [USER_ROLES.SALES],
  },
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/tenders/new',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/opportunity/${props.match.params.opportunityId}/marketplace/new`}
      />
    ),
    roles: [USER_ROLES.SALES],
  },
  {
    isProtected: true,
    exact: true,
    path: '/opportunity/:opportunityId/tenders/update',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/opportunity/${props.match.params.opportunityId}/marketplace/update`}
      />
    ),
    roles: [USER_ROLES.SALES],
  },
]

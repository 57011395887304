import React from 'react'
import PropTypes from 'prop-types'

const EmailLink = ({email, showIcon}) =>
  email ? (
    <>
      {showIcon && <i className="icon-envelope align-middle" />}{' '}
      <a href={`mailto:${email}`}>{email}</a>
    </>
  ) : null

EmailLink.defaultProps = {
  showIcon: false,
}

EmailLink.propTypes = {
  email: PropTypes.string,
  showIcon: PropTypes.bool,
}

export default EmailLink

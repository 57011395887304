import React from 'react'
import PropTypes from 'prop-types'
import size from 'lodash/size'

import {Row, Col} from 'reactstrap'

import {StepperProvider} from './context'
import Steps from './Steps'
import Progress from './Progress'
import Toolbar from './Toolbar'

export {useStepper} from './context'

const Stepper = ({
  steps,
  initialStep,
  previousBtnLabel,
  isPreviousDisabled,
  nextBtnLabel,
  isNextDisabled,
  finishBtnLabel,
  isFinishDisabled,
  additionalBtns,
  onClickFinish,
}) => {
  const toolbarProps = {
    previousBtnLabel,
    nextBtnLabel,
    finishBtnLabel,
    isPreviousDisabled,
    isNextDisabled,
    isFinishDisabled,
    onClickFinish,
    children: additionalBtns,
  }

  return (
    <StepperProvider initialStep={initialStep} totalSteps={size(steps)}>
      <Row className="no-gutters mb-4">
        <Col>
          <Progress steps={steps} />
        </Col>
      </Row>
      <Row className="no-gutters mb-4">
        <Col>
          <Steps steps={steps} />
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col>
          <Toolbar {...toolbarProps} />
        </Col>
      </Row>
    </StepperProvider>
  )
}

Stepper.defaultProps = {
  initialStep: 0,
}

Stepper.propTypes = {
  additionalBtns: PropTypes.node,
  finishBtnLabel: PropTypes.node,
  initialStep: PropTypes.number,
  isFinishDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isPreviousDisabled: PropTypes.bool,
  nextBtnLabel: PropTypes.node,
  onClickFinish: PropTypes.func,
  previousBtnLabel: PropTypes.node,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      progressLabel: PropTypes.string,
      children: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}

export default Stepper

import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'

import Text from 'components/Text'
import StepProgress, {Step} from 'components/StepProgress'
import {useStepper} from './context'

const Progress = ({steps}) => {
  const {currentStep, totalSteps} = useStepper()
  const stepLabelSize = Math.round(12 / totalSteps)

  return (
    <StepProgress>
      {map(steps, (step, index) => (
        <Step
          key={index}
          isActive={index <= currentStep}
          containerColumnProps={{xs: 12, md: stepLabelSize}}>
          <Text>{get(step, 'progressLabel', `Step ${index}`)}</Text>
        </Step>
      ))}
    </StepProgress>
  )
}

Progress.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      progressLabel: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default Progress

import 'cookieconsent/build/cookieconsent.min'
import 'cookieconsent/build/cookieconsent.min.css'

const config = {
  palette: {
    popup: {
      background: '#1d8a8a',
    },
    button: {
      background: '#62ffaa',
    },
  },
  theme: 'edgeless',
  content: {
    message:
      'Diese Webseite nutzt Cookies für die bestmögliche Nutzererfahrung',
    dismiss: 'Einverstanden!',
    link: 'Mehr erfahren',
  },
}

if (window.cookieconsent) {
  window.cookieconsent.initialise(config)
}

import React from 'react'
import {Button} from 'reactstrap'

import useFormField from '../hooks/useFormField'

const SubmitButton = (props) => {
  const {
    isDisabled: isButtonDisabled,
    formContext: {dirty},
  } = useFormField(props)

  const isDisabled = isButtonDisabled || !dirty

  return <Button {...props} disabled={isDisabled} type="submit" value={null} />
}

SubmitButton.propTypes = {}

export default SubmitButton

import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'

import {TabContent, TabPane} from 'reactstrap'

import {useStepper} from './context'

const Steps = ({steps}) => {
  const {currentStep} = useStepper()

  return (
    <TabContent className="p-0 border-0" activeTab={currentStep}>
      {map(steps, (step, index) => (
        <TabPane className="p-0" key={index} tabId={index}>
          {get(step, 'children', null)}
        </TabPane>
      ))}
    </TabContent>
  )
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
}

export default Steps

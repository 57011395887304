import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './index.module.scss'

const Outline = ({show, color, badge, children}) => {
  return (
    <div
      className={classNames(styles.container, {
        'p-1': show,
        border: show,
        [`border-${color}`]: show,
        [styles.containerShown]: show,
      })}>
      {children}
      {show && badge && <span className={styles.badgeContainer}>{badge}</span>}
    </div>
  )
}

Outline.defaultProps = {
  show: false,
  color: 'warning',
}

Outline.propTypes = {
  badge: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.string,
  show: PropTypes.bool,
}

export default Outline

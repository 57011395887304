import {lazy} from 'react'

export default [
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/jobs',
    component: lazy(() => import('./JobsOverview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/jobs/overview/fragments',
    component: lazy(() => import('./JobsOverview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/job-applications',
    component: lazy(() => import('./JobApplications')),
  },
]

import {useEffect} from 'react'
import get from 'lodash/get'
import size from 'lodash/size'
import {gql, useMutation} from '@apollo/client'

import {notifyError, notifySuccess} from 'utils/notificationUtils.js'
import {utils} from 'modules/common/Form'

const UPDATE_PARTNER_DETAILS_MUTATION = gql`
  mutation updatePartnerDetails(
    $partnerId: ID!
    $updates: PartnerUpdatesInput!
  ) {
    updatePartnerDetails(partnerId: $partnerId, updates: $updates)
  }
`

export default function useUpdate(
  partnerId,
  initialValues,
  {clearSubmittingAfterSubmit = true} = {}
) {
  const [updatePartnerDetails, {data, error}] = useMutation(
    UPDATE_PARTNER_DETAILS_MUTATION
  )

  const handleOnSubmit = async (values, {setSubmitting}) => {
    const updates = utils.getUpdates(initialValues, values)
    try {
      await updatePartnerDetails({variables: {partnerId, updates}})
    } catch (err) {
      clearSubmittingAfterSubmit && setSubmitting(false)
    }
    clearSubmittingAfterSubmit && setSubmitting(false)
  }

  useEffect(() => {
    const updatePartnerDetailsMutationData = get(data, 'updatePartnerDetails')
    if (error || updatePartnerDetailsMutationData === null) {
      notifyError(
        'Fehler beim Hinzufügen des Partner details. Bitte versuchen Sie es später noch einmal.'
      )
    } else if (size(updatePartnerDetailsMutationData)) {
      notifySuccess('Informationen erfolgreich hinzugefügt.')
    }
  }, [data, error])

  const success = !error && !!size(get(data, 'updatePartnerDetails'))

  return {handleOnSubmit, success}
}

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'

import {pageView} from 'modules/common/utils/analytics'
import {isFragmentUrl} from 'modules/common/NewVersion/utils'

const PageView = ({children}) => {
  const location = useLocation()

  useEffect(() => {
    // do not fire multiple page views
    // when rendered as a fragment inside new partner app
    if (isFragmentUrl(location.pathname)) return

    pageView(location.pathname)
  }, [location.pathname])

  return <>{children}</>
}

PageView.propTypes = {
  children: PropTypes.node,
}

export default PageView

import React, {useContext, useEffect} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore'
import {auth, db} from 'configs/firebase'
import get from 'lodash/get'

import {getUserRoles} from './userUtils'
import {
  identifyUser,
  identifyUserProperties,
} from 'modules/common/utils/analytics'

const UserContext = React.createContext({
  error: null,
  loading: true,
  value: null,
})

export const useUser = () => useContext(UserContext)

export const useUserPermissions = () => {
  const {value: user, loading} = useUser()
  const isAdmin = !!get(user, 'permissions.admin', false)
  const isPartner = !!get(user, 'partnerId')

  return {isAdmin, isPartner, user, loading}
}

export const useUserQuery = (userId) => {
  const docRef = userId ? db.doc(`users/${userId}`) : null
  const {error, loading, value} = useDocumentDataOnce(docRef)

  if (value) {
    const {permissions} = value
    if (!permissions || !permissions.isEnabled)
      return {error, loading, value: null}
  }

  return {error, loading, value}
}

// eslint-disable-next-line react/prop-types
export const UserProvider = ({children}) => {
  const {initialising, user} = useAuthState(auth)
  const {error, loading, value} = useUserQuery(user && user.uid)

  const isLoading =
    initialising || loading || (user !== null && typeof value === 'undefined')

  // set unique user id for analytics
  useEffect(() => {
    if (user) {
      const userId = get(user, 'uid')
      identifyUser(userId)
    }
  }, [user])

  // set user roles for analytics
  useEffect(() => {
    if (value) {
      const userId = get(value, 'uid')
      const roles = getUserRoles(value)
      identifyUserProperties(userId, {roles})
    }
  }, [value])

  return (
    <UserContext.Provider value={{error, value, loading: isLoading}}>
      {children}
    </UserContext.Provider>
  )
}

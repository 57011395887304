import React, {useState, useLayoutEffect} from 'react'
import PropTypes from 'prop-types'
import defaults from 'lodash/defaults'
import noop from 'lodash/noop'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import classNames from 'classnames'
import {formatDate} from 'utils/dateUtils'
import deLocaleMap from './locale/de'

import 'react-day-picker/lib/style.css'
import './index.scss'

// eslint-disable-next-line react/prop-types
function CustomOverlay({classNames, children, selectedDay, openTop, ...props}) {
  return (
    <div
      // eslint-disable-next-line react/prop-types
      className={classNames.overlayWrapper}
      // show the calendar above the input box
      style={openTop ? {marginTop: -340} : null}
      {...props}>
      {/* eslint-disable-next-line react/prop-types */}
      <div className={classNames.overlay}>{children}</div>
    </div>
  )
}

const DatePicker = ({
  openTop,
  inputProps,
  dayPickerProps,
  onDayPickerHide,
  onDayPickerShow,
  inputRef,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [pickerStyle, setPickerStyle] = useState({})

  const handleOnDayPickerHide = () => {
    setIsVisible(false)
    onDayPickerHide()
  }

  const handleOnDayPickerShow = () => {
    setIsVisible(true)
    onDayPickerShow()
  }

  useLayoutEffect(() => {
    if (openTop && isVisible) {
      // normalize form-control height collapse
      // caused when setting negative margin
      setPickerStyle({marginBottom: 37})
    } else {
      setPickerStyle({})
    }
  }, [isVisible, openTop])

  const computedInputProps = inputProps
    ? {
        ...inputProps,
        className: classNames('form-control', inputProps.className),
      }
    : {className: 'form-control'}

  return (
    <DayPickerInput
      {...rest}
      ref={inputRef}
      style={pickerStyle}
      overlayComponent={(props) => (
        <CustomOverlay {...props} openTop={openTop} />
      )}
      dayPickerProps={defaults(dayPickerProps, deLocaleMap)}
      onDayPickerHide={handleOnDayPickerHide}
      onDayPickerShow={handleOnDayPickerShow}
      inputProps={computedInputProps}
    />
  )
}

DatePicker.defaultProps = {
  placeholder: 'DD.MM.YYY',
  formatDate: (date) => formatDate(date),
  inputProps: {},
  openTop: false,
  onDayPickerHide: noop,
  onDayPickerShow: noop,
}

DatePicker.propTypes = {
  ...DatePicker.propTypes,
  openTop: PropTypes.bool,
}

export default DatePicker

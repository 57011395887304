import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useFormikContext} from 'formik'

import {Row, Col, Card, CardBody} from 'reactstrap'
import SubmitButton from '../SubmitButton'
import ResetButton from '../ResetButton'

import styles from './index.module.scss'
import useNavigationPrompt from 'hooks/useNavigationPrompt'

const SaveDiscard = ({
  title,
  isFloating,
  hideOnClean,
  showPromptOnNavigation,
  navigationPromptMessage,
}) => {
  const {dirty} = useFormikContext()

  useNavigationPrompt(showPromptOnNavigation && dirty, navigationPromptMessage)

  if (!dirty && hideOnClean) return null

  let Container = Fragment
  let BodyContainer = Fragment
  const containerProps = {}

  if (isFloating) {
    Container = Card
    BodyContainer = CardBody
    containerProps.className = classNames(styles.isFloating)
  }

  return (
    <Container {...containerProps}>
      <BodyContainer>
        {title && <div className="mb-3">{title}</div>}
        <Row>
          <Col className="justify-content-start">
            <ResetButton name="resetButton" color="danger">
              Verwerfen
            </ResetButton>
          </Col>
          <Col className="justify-content-end text-right">
            <SubmitButton name="submitButton" color="success">
              Speichern
            </SubmitButton>
          </Col>
        </Row>
      </BodyContainer>
    </Container>
  )
}

SaveDiscard.defaultProps = {
  isFloating: false,
  hideOnClean: false,
  showPromptOnNavigation: true,
  navigationPromptMessage:
    'Änderungen nicht gespeichert. Möchten Sie wirklich von der Seite weg navigieren?',
  title: 'Änderungen Speichern?',
}

SaveDiscard.propTypes = {
  hideOnClean: PropTypes.bool,
  isFloating: PropTypes.bool,
  navigationPromptMessage: PropTypes.string,
  showPromptOnNavigation: PropTypes.bool,
  title: PropTypes.node,
}

export default SaveDiscard

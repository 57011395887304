import React from 'react'
import PropTypes from 'prop-types'
import {
  FormGroup,
  Label,
  Input as BaseInput,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'

import useFormField from '../hooks/useFormField'

const Input = ({
  label,
  labelClassName,
  formGroupClassName,
  suffix,
  showErrorMessage,
  ...props
}) => {
  const {
    id,
    hasError,
    isDisabled,
    field,
    meta: {error},
  } = useFormField(props)

  const labelField = label ? (
    <Label htmlFor={id} className={labelClassName}>
      {label}
    </Label>
  ) : null

  const input = (
    <BaseInput
      {...field}
      {...props}
      id={id}
      disabled={isDisabled}
      invalid={hasError}
    />
  )

  return (
    <FormGroup className={formGroupClassName}>
      {labelField}
      {suffix ? (
        <InputGroup>
          {input}
          <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
        </InputGroup>
      ) : (
        input
      )}
      {hasError && showErrorMessage ? (
        <FormFeedback>{error}</FormFeedback>
      ) : null}
    </FormGroup>
  )
}

Input.defaultProps = {
  suffix: null,
  showErrorMessage: true,
}

Input.propTypes = {
  formGroupClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  showErrorMessage: PropTypes.bool,
  suffix: PropTypes.string,
}

export default Input

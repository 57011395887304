import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import noop from 'lodash/noop'

import Form from 'modules/common/Form'
import useUpdate from 'modules/partners/components/partner/PartnerForm/hooks/useUpdate'
import Steps from './Steps'
import validationSchema from './schema'

const PartnerOnboarding = ({partner, onClickSkip, onFinish}) => {
  const partnerId = get(partner, 'uid')
  const initialValues = {
    services: get(partner, 'services') || [],
    products: get(partner, 'products') || [],
  }
  const {handleOnSubmit, success} = useUpdate(partnerId, initialValues)

  useEffect(() => {
    if (success) onFinish()
  }, [success, onFinish])

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      showSaveDiscard={false}>
      <Steps partner={partner} onClickSkip={onClickSkip} />
    </Form>
  )
}

PartnerOnboarding.defaultProps = {
  onClickSkip: noop,
  onFinish: noop,
}

PartnerOnboarding.propTypes = {
  onClickSkip: PropTypes.func,
  onFinish: PropTypes.func,
  partner: PropTypes.object.isRequired,
}

export default PartnerOnboarding

// @TODO: change `url` to relative urls
// as and when we migrate the pages
// also remove {active: false} from the config
// as that would be handled by the `AppSidebarNav`

export default [
  {
    name: 'Aufträge',
    url: '/orders',
    icon: 'icon-speedometer',
    admin: false,
  },
  {
    name: 'Projekte',
    url: '/jobs',
    icon: 'icon-basket',
    admin: false,
  },
  {
    name: 'Partner',
    url: '/partners',
    icon: 'icon-briefcase',
    admin: true,
    children: [
      {
        name: 'Liste',
        url: '/partners/list',
        icon: 'icon-list',
      },
      {
        name: 'Karte',
        url: '/partners/map',
        icon: 'icon-map',
      },
      {
        name: 'Neu Erstellen',
        url: '/partners/new',
        icon: 'icon-notebook',
      },
    ],
  },
  {
    name: 'Rechnungen',
    url: `/invoices`,
    icon: 'icon-doc',
    admin: true,
  },
  {
    name: 'Protokolle',
    url: `/protocols`,
    icon: 'icon-key',
    admin: true,
    children: [
      {
        name: 'Abnahmeprotokolle',
        url: '/protocols/acceptance',
        icon: 'icon-docs',
      },
      {
        name: 'Bauamt',
        url: '/protocols/authority',
        icon: 'fa fa-university',
      },
    ],
  },
  {
    name: 'Benutzer',
    url: `/users`,
    icon: 'icon-people',
    admin: true,
  },
]

import React from 'react'
import PropTypes from 'prop-types'
import defaults from 'lodash/defaults'
import identity from 'lodash/identity'
import {Formik, Form as FormikForm} from 'formik'

import SaveDiscard from '../SaveDiscard'
import ValuesChangeSubscriber from '../ValuesChangeSubscriber'

const DEFAULT_SAVE_DISCARD_PROPS = {
  isFloating: true,
  hideOnClean: true,
  showPromptOnNavigation: true,
  navigationPromptMessage:
    'Änderungen nicht gespeichert. Möchten Sie wirklich von der Seite weg navigieren?',
  title: 'Änderungen Speichern?',
}

const Form = ({
  initialValues,
  validationSchema,
  children,
  onSubmit,
  onReset,
  onChange,
  showSaveDiscard,
  saveDiscardProps,
  saveDiscardRenderer,
  validateOnChange,
  validateOnBlur,
  enableReinitialize,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    onReset={onReset}
    validateOnChange={validateOnChange}
    validateOnBlur={validateOnBlur}
    enableReinitialize={enableReinitialize}>
    <FormikForm>
      {children}
      {showSaveDiscard &&
        saveDiscardRenderer(
          <SaveDiscard
            {...defaults({}, saveDiscardProps, DEFAULT_SAVE_DISCARD_PROPS)}
          />
        )}
      {onChange && <ValuesChangeSubscriber onChange={onChange} />}
    </FormikForm>
  </Formik>
)

Form.defaultProps = {
  showSaveDiscard: true,
  saveDiscardProps: DEFAULT_SAVE_DISCARD_PROPS,
  saveDiscardRenderer: identity,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: false,
}

Form.propTypes = {
  children: PropTypes.node,
  enableReinitialize: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  saveDiscardProps: PropTypes.shape({
    isFloating: PropTypes.bool,
    hideOnClean: PropTypes.bool,
    title: PropTypes.node,
    showPromptOnNavigation: PropTypes.bool,
    navigationPromptMessage: PropTypes.string,
  }),
  saveDiscardRenderer: PropTypes.func,
  showSaveDiscard: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

export default Form

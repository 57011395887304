import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

import {Row, Col} from 'reactstrap'
import {CheckboxGroup} from 'modules/common/Form'
import useProductsConfig from 'hooks/useProductsConfig'

import size from 'lodash/size'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'

import Outline from 'components/Outline'
import MissingDetailsBadge, {
  hasMissingDetailsForPartner,
} from 'modules/partners/components/partner/PartnerMetaBadges/MissingDetailsBadge'

import {PRODUCTS_CATEGORIES} from 'utils/const'
import getProductCategoryName from 'utils/getProductCategoryName'

import styles from './index.module.scss'

const PartnerProducts = ({
  labelClassName,
  disabled,
  showOutlineForMissingDetails,
  partner,
  hideDeleted,
}) => {
  const {formatted: productOptions} = useProductsConfig({hideDeleted})
  const hasProducts = size(get(partner, 'products'))

  const showOutline =
    showOutlineForMissingDetails && hasMissingDetailsForPartner(partner)
  const missingDetailsBadgeNode = showOutline ? (
    <MissingDetailsBadge partner={partner} />
  ) : null

  const groupedProductOptions = useMemo(
    () => groupBy(productOptions, 'type'),
    [productOptions]
  )

  const renderOptionsGroup = (options, label) => {
    if (!size(options)) return null

    return (
      <CheckboxGroup
        name="products"
        options={options}
        label={label}
        labelClassName={labelClassName}
        disabled={disabled}
        optionsContainerClassName={styles.optionsContainer}
        columnsCount={2}
      />
    )
  }

  return (
    <>
      <Outline
        show={showOutline && !hasProducts}
        badge={missingDetailsBadgeNode}>
        <Row>
          <Col xs="12" md="6">
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.general],
                  getProductCategoryName(PRODUCTS_CATEGORIES.general)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.architecture],
                  getProductCategoryName(PRODUCTS_CATEGORIES.architecture)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.structuralEngineer],
                  getProductCategoryName(PRODUCTS_CATEGORIES.structuralEngineer)
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[
                    PRODUCTS_CATEGORIES.buildingServicesPlanning
                  ],
                  getProductCategoryName(
                    PRODUCTS_CATEGORIES.buildingServicesPlanning
                  )
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="6">
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.soil],
                  getProductCategoryName(PRODUCTS_CATEGORIES.soil)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.fireProtection],
                  getProductCategoryName(PRODUCTS_CATEGORIES.fireProtection)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[
                    PRODUCTS_CATEGORIES.energyAndThermalProtection
                  ],
                  getProductCategoryName(
                    PRODUCTS_CATEGORIES.energyAndThermalProtection
                  )
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.houseTechnic],
                  getProductCategoryName(PRODUCTS_CATEGORIES.houseTechnic)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.roof],
                  getProductCategoryName(PRODUCTS_CATEGORIES.roof)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.shell],
                  getProductCategoryName(PRODUCTS_CATEGORIES.shell)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.finishingTrades],
                  getProductCategoryName(PRODUCTS_CATEGORIES.finishingTrades)
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {renderOptionsGroup(
                  groupedProductOptions[PRODUCTS_CATEGORIES.construction],
                  getProductCategoryName(PRODUCTS_CATEGORIES.construction)
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Outline>
    </>
  )
}

PartnerProducts.defaultProps = {
  disabled: false,
  showOutlineForMissingDetails: false,
  hideDeleted: false,
  labelClassName: 'font-weight-bold',
}

PartnerProducts.propTypes = {
  disabled: PropTypes.bool,
  hideDeleted: PropTypes.bool,
  labelClassName: PropTypes.string,
  partner: PropTypes.object,
  showOutlineForMissingDetails: PropTypes.bool,
}

export default PartnerProducts

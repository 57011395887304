import {utils} from 'modules/common/Form'
const {schema} = utils

export const contactSchema = schema.object({
  person: schema
    .string()
    .required('Name der Hauptkontakt person erforderlich!'),
  phone: schema.string().required('Eine Haupttelefonnummer ist erforderlich!'),
  email: schema
    .string()
    .email('Bitte geben Sie eine gültige Email-Adresse ein!')
    .required('Eine Hauptkontakt E-Mail ist erforderlich!'),
  website: schema.string().url('Bitte betreten Sie eine Website!'),
})

export const addressSchema = schema.object({
  street: schema.string().required('Straßenname ist erforderlich!'),
  zip: schema.string().required('Postleitzahl ist erforderlich!'),
  city: schema.string().required('Stadtname ist erforderlich!'),
  country: schema.string().required('Land ist erforderlich!'),
})

export const invoiceDetailsSchema = schema.object({
  owner: schema.string(),
  companyName: schema.string(),
  bankName: schema.string(),
  bic: schema.string(),
  iban: schema.string(),
  taxId: schema.string(),
  street: schema.string(),
  zip: schema.string(),
  city: schema.string(),
  country: schema.string(),
})

export const emailsSchema = schema.array().of(
  schema.object({
    email: schema
      .string()
      .email('Bitte geben Sie eine gültige Email-Adresse ein!')
      .required('E-Mail ist erforderlich!'),
    name: schema.string(),
  })
)

export const phonesSchema = schema.array().of(
  schema.object({
    value: schema.string().required('Telefonnummer ist erforderlich!'),
    label: schema.string(),
  })
)

export const productsSchema = schema.array().of(schema.string())

export const servicesSchema = schema.array().of(schema.string())

export default schema.object({
  isEnabled: schema.boolean(),
  name: schema.string().required('Firmenname ist erforderlich!'),
  contact: contactSchema,
  address: addressSchema,
  invoiceDetails: invoiceDetailsSchema,
  internalComment: schema.string(),
  services: servicesSchema,
  products: productsSchema,
  weaknesses: schema.array().of(schema.string()),
  strengths: schema.array().of(schema.string()),
  categories: schema.array().of(schema.string()),
  emails: emailsSchema,
  phones: phonesSchema,
})

import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'

export default [
  {
    isProtected: true,
    exact: true,
    path: '/offer/:opportunityId/create',
    component: (props) => (
      // eslint-disable-next-line react/prop-types
      <Redirect to={`/offers/${props.match.params.opportunityId}/create`} />
    ),
  },
  {
    isProtected: true,
    exact: true,
    path: '/offer/:opportunityId/partner-search',
    component: (props) => (
      <Redirect
        // eslint-disable-next-line react/prop-types
        to={`/offers/${props.match.params.opportunityId}/partner-search`}
      />
    ),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/offers/:opportunityId/partner-search',
    component: lazy(() => import('./PartnerSearch')),
  },
  {
    isProtected: true,
    exact: false,
    path: '/offers/:opportunityId/(.*)?',
    component: lazy(() => import('./OfferCreation')),
  },
]

import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import * as ReactRouter from 'react-router-dom'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'

import {useUser} from 'modules/auth/utils/UserContext'
import {signOut} from 'modules/auth/utils/loginUtils'
import usePartnerOnce from 'modules/partners/hooks/usePartnerOnce'
import {hasMissingDetails} from 'modules/partners/utils/missingInfoUtils'
import PartnerOnboardingPopup from 'modules/partners/components/PartnerOnboardingPopup'
import LayoutComponent from 'components/Layout'
import navList from './nav-list'
import Loader from 'components/Loader'
import GuidedTour from '../GuidedTour'

const guidedTourTargets = [
  {
    helpKey: 'menu-toggler',
    target: '.app-header.navbar button.d-md-down-none[data-sidebar-toggler]',
  },
  {
    helpKey: 'menu-orders-overview',
    target: '.sidebar .nav > .nav-item a[href="/orders"]',
  },
  {
    helpKey: 'menu-jobs-overview',
    target: '.sidebar .nav > .nav-item a[href="/jobs"]',
  },
  {
    helpKey: 'menu-settings',
    target: '.sidebar .nav > .nav-item a[href="/settings"]',
  },
]

const Layout = ({children, ...props}) => {
  const {loading, value: user} = useUser()
  const partnerId = get(user, 'partnerId', null)
  const isUserEnabled = get(user, 'permissions.isEnabled', false)
  const isUserAdmin = get(user, 'permissions.admin', false)
  const {value: partner} = usePartnerOnce(partnerId)
  const isMarketplaceEnabled = get(partner, 'isMarketplaceEnabled', false)
  const hasMissingPartnerDetails = useMemo(
    () => partner && hasMissingDetails(partner),
    [partner]
  )
  const navigationItems = useMemo(() => {
    let items = cloneDeep(navList)
    // if user is not admin, remove all items that are marked as admin only
    if (!isUserAdmin) {
      if (isMarketplaceEnabled) {
        items = filter(navList, (navItem) => !navItem.admin)
      } else {
        items = filter(
          navList,
          (navItem) => !navItem.admin && !navItem.showOnlyMarketplacePartners
        )
      }
    } else {
      // if user is admin, remove all items that are marked as partner only
      items = filter(navList, (navItem) => !navItem.partner)
    }
    // if the logged in user is a partner, add the settings menu.
    // also show a warning icon if there are missing information
    if (partnerId) {
      items.push({
        name: 'Einstellungen',
        url: '/settings',
        icon: 'icon-user',
        badge: hasMissingPartnerDetails
          ? {
              variant: 'warning',
              text: <i className="fa fa-warning" />,
            }
          : null,
      })
    }

    return items
  }, [isUserAdmin, partnerId, isMarketplaceEnabled, hasMissingPartnerDetails])

  if (loading) return <Loader />

  if (!user || !isUserEnabled) return children

  const navigation = {items: navigationItems}

  return (
    <>
      <GuidedTour tourId="common" targets={guidedTourTargets} />
      <LayoutComponent
        user={user}
        navigation={navigation}
        onClickLogout={signOut}
        router={ReactRouter}
        sidebarNavProps={{...props, navConfig: navigation}}>
        {children}
        {partner && <PartnerOnboardingPopup partner={partner} />}
      </LayoutComponent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default ReactRouter.withRouter(Layout)

import React from 'react'

import {
  CONSTRUYO_URL,
  CONSTRUYO_AGB_URL,
  CONSTRUYO_IMPRESSUM_URL,
} from 'utils/const'

const LayoutFooter = () => (
  <>
    <span className="float-sm-left d-block">
      <a href={CONSTRUYO_AGB_URL} target="_blank" rel="noopener noreferrer">
        AGB
      </a>{' '}
      &bull;{' '}
      <a
        href={CONSTRUYO_IMPRESSUM_URL}
        target="_blank"
        rel="noopener noreferrer">
        Impressum
      </a>
    </span>

    <span className="float-sm-right d-block">
      <a href={CONSTRUYO_URL} target="_blank" rel="noopener noreferrer">
        Construyo
      </a>{' '}
      &copy; 2023 Construyo GmbH.
    </span>
  </>
)

export default LayoutFooter

import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'

import {useUserPermissions} from 'modules/auth/utils/UserContext'
import {notifyLegacyApp} from 'utils/notificationUtils'
import {useShouldRedirect} from './hooks'
import {isFragmentUrl} from './utils'
import {locationSearchParamToSearchState} from 'modules/common/InstantSearch/hooks/useUrl'

const NewVersion = ({children}) => {
  const location = useLocation()
  const {loading} = useUserPermissions()
  const shouldRedirect = useShouldRedirect()

  useEffect(() => {
    const {is_filter: isFilter} = locationSearchParamToSearchState(
      location.search || ''
    )
    if (!isFilter && isFragmentUrl(location.pathname)) {
      notifyLegacyApp({type: 'urlChange', message: location})
    }
  }, [location])

  useEffect(() => {
    if (loading) return

    if (shouldRedirect) {
      window.location.replace(
        `/v3${location.pathname}${location.search}${location.hash}`
      )
    }
  }, [location, loading, shouldRedirect])

  if (loading || shouldRedirect) return null

  return <>{children}</>
}

NewVersion.propTypes = {
  children: PropTypes.node,
}

export default NewVersion

import React from 'react'
import PropTypes from 'prop-types'
import {Badge} from 'reactstrap'
import get from 'lodash/get'

export const hasMissingDetailsForPartner = (partner) =>
  get(partner, 'meta.missingDetails', false)

const MissingDetailsBadge = ({partner, className, forceShow}) => {
  const hasMissingDetails = forceShow || hasMissingDetailsForPartner(partner)

  return hasMissingDetails ? (
    <Badge className={className} color="warning">
      Details fehlen
    </Badge>
  ) : null
}

MissingDetailsBadge.defaultProps = {
  forceShow: false,
}

MissingDetailsBadge.propTypes = {
  className: PropTypes.string,
  forceShow: PropTypes.bool,
  partner: PropTypes.object,
}

export default MissingDetailsBadge

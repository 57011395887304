import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import {Redirect} from 'react-router-dom'

const RedirectToLoginPage = ({fromLocation}) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const currentUrl = window.location.href
      const query = qs.stringify({redirect_uri: currentUrl})
      window.location.replace(`/login?${query}`)
    }
  }, [])

  if (process.env.NODE_ENV === 'production') return null

  // redirect to local login page in dev environment
  return (
    <Redirect to={{pathname: '/auth/login', state: {from: fromLocation}}} />
  )
}

RedirectToLoginPage.propTypes = {
  fromLocation: PropTypes.object,
}

export default RedirectToLoginPage

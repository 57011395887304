import React, {useCallback, useMemo} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import find from 'lodash/find'
import noop from 'lodash/noop'
import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import concat from 'lodash/concat'

import BaseSelect from 'react-select'
import {FormGroup, Label, FormFeedback} from 'reactstrap'
import useFormField from '../hooks/useFormField'
import {getTheme, getCustomStyles} from 'components/Select/utils'

const pickValue = (option) => get(option, 'value', '')
const filterMultiSelectValue = (options, value) => {
  const normalizedOptions = reduce(
    options,
    (res, option) => {
      const nestedOption = get(option, 'options')
      return concat(res, isArray(nestedOption) ? nestedOption : option)
    },
    []
  )

  return filter(normalizedOptions, (option) =>
    includes(value, pickValue(option))
  )
}

const filterSelectValue = (options, value) =>
  find(options, (option) => pickValue(option) === value)

const Select = ({
  label,
  labelClassName,
  formGroupClassName,
  isMulti,
  styles,
  showErrorMessage,
  onChange,
  ...props
}) => {
  const {
    id,
    hasError,
    isDisabled,
    field,
    helpers: {setValue},
    meta: {error},
  } = useFormField({...props, type: 'select'})

  const options = useMemo(() => get(props, 'options') || [], [props])
  const defaultValue = useMemo(() => (isMulti ? [] : ''), [isMulti])
  const filterValueFn = useMemo(
    () => (isMulti ? filterMultiSelectValue : filterSelectValue),
    [isMulti]
  )
  const value = useMemo(
    () => (options ? filterValueFn(options, field.value) : defaultValue),
    [filterValueFn, defaultValue, options, field.value]
  )

  const handleOnChange = useCallback(
    (option) => {
      setValue(isMulti ? map(option, pickValue) : pickValue(option))
      onChange(option)
    },
    [isMulti, setValue, onChange]
  )

  const handleOnBlur = useCallback(
    (e) => {
      e.target.name = props.name
      field.onBlur(e)
    },
    [props, field]
  )

  const labelField = label ? (
    <Label htmlFor={id} className={labelClassName}>
      {label}
    </Label>
  ) : null

  return (
    <FormGroup className={formGroupClassName}>
      {labelField}
      <BaseSelect
        {...field}
        {...props}
        isMulti={isMulti}
        inputId={id}
        isDisabled={isDisabled}
        styles={getCustomStyles(hasError, styles)}
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        theme={getTheme}
      />
      {hasError && showErrorMessage ? (
        <>
          <div className="custom-control-input is-invalid" />
          <FormFeedback>{error}</FormFeedback>
        </>
      ) : null}
    </FormGroup>
  )
}

Select.defaultProps = {
  isMulti: false,
  showErrorMessage: true,
  onChange: noop,
}

Select.propTypes = {
  formGroupClassName: PropTypes.string,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showErrorMessage: PropTypes.bool,
  styles: PropTypes.object,
}

export default Select

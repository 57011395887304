import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import {USER_ROLES} from 'modules/auth/utils/const'

export default [
  // individual fragments
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/description',
    component: lazy(() => import('./fragments/OrderDescriptionFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/documents',
    component: lazy(() => import('./fragments/OrderDocumentsFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/delivery',
    component: lazy(() => import('./fragments/OrderDeliveryFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/assignment',
    component: lazy(() => import('./fragments/OrderAssignmentFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/comments/:type?',
    component: lazy(() => import('./fragments/OrderCommentsFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/changelog',
    component: lazy(() => import('./fragments/OrderChangelogFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/messages',
    component: lazy(() => import('./fragments/OrderMessagesFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments/revisions',
    component: lazy(() => import('./fragments/OrderRevisionsFragment')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId',
    component: lazy(() => import('./OrderDetails')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/overview/fragments',
    component: lazy(() => import('./OrdersOverview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/fragments',
    component: lazy(() => import('./OrderDetails')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders',
    component: lazy(() => import('./OrdersOverview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId/revisions',
    component: lazy(() => import('./OrderRevision/CreateOrUpdateRevision')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId/revisions/new',
    component: lazy(() => import('./OrderRevision/NewOrderRevision')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId/revisions/:revisionId',
    component: lazy(() => import('./OrderRevision')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId/protocols/new',
    component: lazy(() => import('./OrderAcceptanceProtocolCreation')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/orders/:orderId/protocols/:acceptanceProtocolId',
    component: lazy(() => import('./OrderAcceptanceProtocolPreview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/orders/:orderId/admin',
    component: lazy(() => import('./OrderAdmin')),
    roles: [USER_ROLES.ADMIN, USER_ROLES.FINANCE, USER_ROLES.OPS],
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/opportunity/:opportunityId/orders',
    component: lazy(() => import('./OrderRelated')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: true,
    path: '/opportunity/:opportunityId/orders/overview/fragments',
    component: lazy(() => import('./OrderRelated')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/protocols/acceptance',
    component: lazy(() => import('./OrderAcceptanceProtocolsOverview')),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/protocols/authority',
    component: lazy(() =>
      import('./OrderAcceptanceProtocolsBuildingAuthorityOverview')
    ),
  },
  {
    isProtected: true,
    exact: true,
    customLayout: false,
    path: '/protocols',
    component: () => <Redirect to="/protocols/acceptance" />,
  },
]

import qs from 'qs'
import {PARTUM_PARTNER_ID} from './const'
import isEqual from 'lodash/isEqual'
import isFinite from 'lodash/isFinite'

const PD180 = Math.PI / 180
const COS = Math.cos

export const getRandomId = () =>
  `construyo-${Math.random().toString(36).substring(7)}`

export const isPartumNotContractPartner = (partnerUid) =>
  partnerUid !== PARTUM_PARTNER_ID

export const isEqualIds = (id1, id2) => !!(id1 && id2 && id1 === id2)

export const createURLFromLocation = (location) =>
  `${location.pathname}${location.search}`

export const locationSearchParamToSearchState = (search) =>
  search ? qs.parse(search.slice(1)) : {}

// Explanation - https://stackoverflow.com/a/21623206
export const calculateDistanceBetweenTwoPoints = (point1, point2) => {
  if (
    !point1 ||
    !point2 ||
    !isFinite(point1.lat) ||
    !isFinite(point1.lng) ||
    !isFinite(point2.lat) ||
    !isFinite(point2.lng)
  )
    return Infinity

  if (isEqual(point1, point2)) return 0

  const a =
    0.5 -
    COS((point2.lat - point1.lat) * PD180) / 2 +
    (COS(point1.lat * PD180) *
      COS(point2.lat * PD180) *
      (1 - COS((point2.lng - point1.lng) * PD180))) /
      2

  return 12742 * Math.asin(Math.sqrt(a))
}

export const removeTrailingSlash = (url) =>
  url.substr(-1) === '/' ? url.substr(0, url.length - 1) : url
